import React, { useState, forwardRef, useImperativeHandle, useEffect  } from 'react';
import { useSelector } from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import { Offcanvas } from 'react-bootstrap';
import axios from 'axios';


const UbytovatInEmployee = forwardRef((props, ref) => {
   const firstCompany = useSelector(state => (state.company && state.company.firstCompany) || '');
    const [hostelsData, setHostels] = useState([]);
    const [selectedSelect, setSelectedSelect] = useState(null); 
    const [SelectedHostelName, setSelectedHostelName] = useState(null); 
    const {CheckBoxEmployees, clearAllCheckboxes, setEventsUpdateTrigger} = props;
    const [Rooms, setRooms] = useState([]);
    const [RoomsCapacity, setRoomsCapacity] = useState([]);
    const [SelectedRoom, setSelectedRoom] = useState(null);


    
    const handleButtonClick = (e) => {
      e.preventDefault();

      const hiddenInput = document.querySelector('.hideInputEmail');
      hiddenInput.style.display = 'none';

    };

  
    useEffect(() => { 
console.log('useEffect - CheckBoxEmployees', CheckBoxEmployees);
}, [CheckBoxEmployees]);
    useEffect(() => { 
    fetchData88();
  }, []);

  useEffect(() => { 

    fetchData88();
  }, [firstCompany]);

  const fetchData88 = async () => {
    try {
      const response = await fetch(`/${firstCompany}/ubytovny.json?${Date.now()}`);
      const data = await response.json();
      setHostels(data);
    } catch (error) {
      console.error('Ошибка при загрузке данных:', error);
    }
  };


    const { setDataUpdateTrigger } = props;
    const [startDate, setStartDate] = useState(new Date());
    const [startDate2, setStartDate2] = useState(new Date());
    const [UbytovatE, setUbytovatE] = useState(false);

    


    const showUbytovatModal = () => {
      setUbytovatE(true);
    };
  
    useImperativeHandle(ref, () => ({
      showUbytovatModal() {
        setUbytovatE(true);
      },
      showUbytovatModal, // Передаем метод в ref
    }));
  
    const nav = useNavigate();
  
    const handleSubmit = async (e) => {
      e.preventDefault();

      const freeSpaceInRoom = Rooms.find(room => room.id === SelectedRoom);

      console.log('handleSubmit: SelectedRoom ', SelectedRoom);
        console.log('handleSubmit: freeSpaceInRoom.freeSpace ', freeSpaceInRoom.freeSpace);
        console.log('handleSubmit: freeSpaceInRoom ', freeSpaceInRoom);
        console.log('handleSubmit: CheckBoxEmployees ', CheckBoxEmployees);
        console.log('handleSubmit: CheckBoxEmployees.length ', CheckBoxEmployees.length);
        if (freeSpaceInRoom.freeSpace < CheckBoxEmployees.length) {
            alert(`Do pokoje se nevejde tolik lidí. Volná lůžka: ${freeSpaceInRoom.freeSpace}`);
            return;
        }
  
      console.log(CheckBoxEmployees);
      console.log(selectedSelect);
      console.log(SelectedRoom);
      
      const dataToServer = {
        CheckBoxEmployees:CheckBoxEmployees,
        selectedSelect:selectedSelect,
        SelectedRoomID:SelectedRoom,
        SelectedRoomName:freeSpaceInRoom.name,
        firstCompany:firstCompany,
        SelectedHostelName:SelectedHostelName
      };
               

      try {
  
        // Отправка данных на сервер
        axios.post('/UbytovatInEmployee.php', dataToServer)
          .then(response => {
          
            setDataUpdateTrigger((prev) => !prev);
            setEventsUpdateTrigger((prev) => !prev);
          })
          .catch(error => {
            console.error(error);
          });
  
        setUbytovatE(false);
        clearAllCheckboxes();
      } catch (error) {
        console.error('Ошибка при загрузке данных:', error);
      }
    };
      
    



    const fetchDataPokoje = async () => { 
        try {
          const response = await fetch(`/${firstCompany}/pokoje.json?${Date.now()}`);
          const data = await response.json();

          const response2 = await fetch(`/${firstCompany}/file.json?${Date.now()}`);
          const data2 = await response2.json();

          const filteredRooms = data.filter(
            (room) =>
              room.hostelId === selectedSelect 
          );

          console.log('filteredRooms', filteredRooms);

          const roomCount = {};

      data2.forEach((client) => {
        if (client.hostelId === selectedSelect) {
          const key = `${client.hostelId} *-* ${client.roomID}`;
          if (!roomCount[key]) {
            roomCount[key] = 1;
          } else {
            roomCount[key]++;
          }
        }
      });

      console.log('roomCount', roomCount);
    
      const numberOfKeys = Object.keys(roomCount).length;

      if (numberOfKeys > 0) {
        
      const roomCountArray = Object.keys(roomCount).map((key) => {
        const [hostelId, roomID] = key.split(' *-* ');
        return { hostelId, roomID, count: roomCount[key] };
      });

      console.log('1-roomCountArray', roomCountArray);

      const roomsWithFreeSpace = filteredRooms.map((room) => {
        const countObj = roomCountArray.find((obj) => obj.hostelId === room.hostelId && obj.roomID === room.id);
        if (countObj) {
            const quantity = parseInt(room.quantity, 10);
            const count = countObj.count;
            const freeSpace = quantity - count;
            return { ...room, freeSpace };
        } else {
            // Если не удалось найти соответствующий объект в roomCountArray, то считаем, что свободных мест нет.
            return { ...room, freeSpace: room.quantity };
        }
    });
    
    console.log('roomsWithFreeSpace', roomsWithFreeSpace);

    const RoomsWithSpace = roomsWithFreeSpace.filter((room) => room.freeSpace > 0);

    console.log('RoomsWithSpace', RoomsWithSpace);

  setRooms(RoomsWithSpace);
} else {
  setRooms(filteredRooms);
  console.log('filteredRooms-filteredRooms',filteredRooms);
}
          // setRooms(data);
        } catch (error) {
          console.error('Ошибка при загрузке данных:', error);
        }
      };
    


      const fetchDataFile = async () => {
        try {
          const response = await fetch(`/${firstCompany}/file.json?${Date.now()}`);
          const data = await response.json();
      
          
          const roomCounts = {};
          

          // Подсчитываем количество объектов с одинаковыми значениями ключа "room"
          data.forEach(obj => {
            if (obj.hasOwnProperty('room')) {
              const roomValue = obj.roomID;
              roomCounts[roomValue] = (roomCounts[roomValue] || 0) + 1;
            }
          });
      
           setRoomsCapacity(roomCounts);
      
        } catch (error) {
          console.error('Ошибка при загрузке данных:', error);
        }
      };


      useEffect(() => {
        
        fetchDataPokoje();
        fetchDataFile();
      }, [firstCompany]);

      useEffect(() => {
        
        fetchDataPokoje();
        fetchDataFile();
      }, []);


      useEffect(() => {
       
        fetchDataPokoje();
        setTimeout(() => {
          console.log('Rooms1', Rooms);
        },1000);
      }, [selectedSelect]);
    
      const handleUbytovnaSelect = (event) => {
        const selectedHostelInfo = event.target.value.split(',');
          const selectedHostelId = selectedHostelInfo[0];
          const selectedHostelName = selectedHostelInfo[1];
        console.log('handleUbytovnaSelect: selectedHostelInfo ', selectedHostelInfo);
        console.log('handleUbytovnaSelect: selectedHostelId ', selectedHostelId);
        console.log('handleUbytovnaSelect: selectedHostelName ', selectedHostelName);
        setSelectedSelect(selectedHostelId);
        setSelectedHostelName(selectedHostelName);

      };

      const handleRoomSelect = (event) => {
        const selectedHostelName = event.target.value;
        
        setSelectedRoom(selectedHostelName);
        setTimeout(() => {
        console.log('Hotel: selectedSelect', SelectedRoom);
      },900);
      };


    return (
        <>
            <Offcanvas show={UbytovatE} onHide={setUbytovatE} className="offcanvas-end customeoff" placement='end'>
				<div className="offcanvas-header">
              
					<h5 className="modal-title" id="#gridSystemModal">Ubytovat</h5>
					<button type="button" className="btn-close" 
						onClick={()=>setUbytovatE(false)}
					>
						<i className="fa-solid fa-xmark"></i>
					</button>
				</div>
				<div className="offcanvas-body">
                    <div className="container-fluid">
                        
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                            
                                                         

                                <div className="col-xl-6 mb-3">
        <label className="form-label">Ubytovna</label>
        <select
          name="ubytovna"
          className="default-select form-control"
          onChange={handleUbytovnaSelect}
        >
          <option data-display="Select">-</option>
          {hostelsData.map((hostel, index) => (
            <option key={index} value={`${hostel.id},${hostel.name}`}>
              {hostel.name}
            </option>  
          ))}
        </select>
      </div>
      {selectedSelect && (
        <div className="col-xl-6 mb-3">
          <label className="form-label">Pokoj</label>
          <select name="pokoj" className="default-select form-control" onChange={handleRoomSelect}>
            <option data-display="Select">-</option>
            {setTimeout(() => {
              console.log('Rooms2', Rooms)
            },2000)}
            {Rooms.map((room, index) => (
              
              <option
                key={index}
                value={room.id}
              >
                {`Pokoj č. ${room.name} (volno: ${room.freeSpace})`}
              </option>
            ))}
          </select>
        </div>
      )}
     

                            </div>
                            <div>
                                <button type="submit" className="btn btn-primary me-1">Přidat</button>
                                <Link to={"#"} onClick={()=>setUbytovatE(false)} className="btn btn-danger light ms-1">Zrušit</Link>
                            </div>
                        </form>
                    </div>
				</div>
			</Offcanvas>     
        </>
    );
});

export default UbytovatInEmployee;