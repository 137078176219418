import axios from 'axios';
import api from '../config/api.json';

const request = async (url, data = {}) => {
  if (url.search('http') !== 0) {
    url = api.BASE_URL + url
  }

  let headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  if (data) {
    if (data.headers) {
      headers = {
        ...headers,
        ...data.headers,
      }
    }
  }

  const response = await axios.request({
    url: url,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    ...data,
  });

  if (response.status >= 400) {
    throw new Error(response.statusText);
  }

  return response.data;
};

export default request;