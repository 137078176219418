import React from "react";
import ReactApexChart from "react-apexcharts";

function ProjectStatusBlogDonutChart({strediskoCounts, strediskaGraf, barGraf, ostatniWorkers}) {

  // Создаем массив меток (значений свойства 'title' в каждом объекте)
const labelsWithoutThirdPosition = barGraf.map(item => item.title);

// Создаем массив значений 'pracovniky' (ваша специфика)
const seriesWithoutThirdPosition = barGraf.map(item => item.pracovniky);

  const totalPracovniky = barGraf.reduce((total, item) => {
    return total + item.pracovniky;
  }, 0);

  const options = {
    chart: {
      type: "donut",
      width: 250,
    },
    colors: ['var(--primary)',
    '#3AC977',
    '#FF9F00',
    '#FF5E5E'
    ],
    labels: labelsWithoutThirdPosition,
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        donut: {
          size: "90%",
          labels: {
            show: true,
            name: {
              show: true,
              offsetY: 12,
            },
            value: {
              show: true,
              fontSize: "24px",
              fontFamily: "Arial",
              fontWeight: "500",
              offsetY: -17,
            },
            total: {
              show: true,
              fontSize: "11px",
              fontWeight: "500",
              fontFamily: "Arial",
              label: "celkem aktivnich pracovníků",
              // formatter: function (w) {
              //   return w.globals.seriesTotals.reduce((a, b) => {
              //     return strediskaGraf.length;
              //   }, 0);
              // },
              formatter: function () {
                return totalPracovniky + ostatniWorkers;
              },
            },
          },
        },
      },
    },
  };

//   document.addEventListener("DOMContentLoaded", () => {
  
//   setTimeout(() => {

  
//   const projectDates = document.querySelectorAll('.project-date .project-media');
//   const lengthDates = projectDates.length;
//   const toChangeClass = document.querySelectorAll('#SvgjsText1213');
//   toChangeClass.textContent = lengthDates;
// },3000);
//   });

  return (
    <div id="projectChart" className="project-chart">
      <ReactApexChart
        options={options}
        series={seriesWithoutThirdPosition}
        type="donut"
        width={250}
      />
    </div>
  );
}

export default ProjectStatusBlogDonutChart;
