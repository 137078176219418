import React, {useState, useRef, useEffect} from 'react';
import { useSelector } from 'react-redux';
import {Link, useParams} from 'react-router-dom';
import PracovnikyOffcanvas from '../../../constant/PracovnikyOffcanvas';
import axios from 'axios';
import { CSVLink } from 'react-csv'; 

const ProfileTovarnaTableList = () => {
  const firstCompany = useSelector(state => (state.company && state.company.firstCompany) || '');
  let { id } = useParams();
  const [selectedTovarna, setSelectedTovarna] = useState([]);
  // const selectedTovarna = useSelector((state) => state.tovarna.tovarnaData);
    const [dataUpdateTrigger, setDataUpdateTrigger] = useState(false);
    const [Pracovniky, setPracovniky] = useState([]);
    const tovarna = useRef();

  useEffect(() => {
    
    fetchDataPracovniky();
  }, []);

  useEffect(() => {
    fetchDataTovarna();
    fetchDataPracovniky();
  }, [firstCompany]); 

  const fetchDataPracovniky = async () => {
    try {
      const response = await fetch(`/${firstCompany}/file.json?${Date.now()}`);
      const data = await response.json();
      console.log('selectedTovarna -- ProfileTovarnaTableList', selectedTovarna);
      const thisPracovniky = data.filter(pracovnik => pracovnik.tovarnaId === id);
      console.log('ProfileTovarna -- thisPracovniky', thisPracovniky);
      setPracovniky(thisPracovniky);
    } catch (error) {
      console.error('Ошибка при загрузке данных:', error);
    }
  };

  const fetchDataTovarna = async () => {
    

    try {
      const response = await fetch(`/${firstCompany}/tovarny.json?${Date.now()}`);
      
      if (response.ok) {
        try {
          const data = await response.json();
          const foundTovarna = data.find(item => item.id === id);
          setSelectedTovarna(foundTovarna);
        } catch (jsonError) {
          console.error('Ошибка при парсинге JSON:', jsonError);
        }
      } else {
        console.error('Ошибка при загрузке данных:', response.status);
      }
    } catch (error) {
      console.error('Ошибка при загрузке данных:', error);
    }
  };
   
  

  useEffect(() => {
    fetchDataPracovniky();
  }, [dataUpdateTrigger, selectedTovarna]);





  const handleDeletePracovnik = async (pracovikEmail) => {
    
    try { 

      const url = `https://online.jobsi.app/delete_pracovnik.php`;
      const response = await axios.put(url, { pracovikEmail, firstCompany });
  
      // Проверяем успешность удаления данных на сервере
      
      if (response.status !== 200) {
        console.log('Не удалось удалить данные на сервере');
        throw new Error('Не удалось удалить данные на сервере');
      }
      
      const PacovnikIndex = Pracovniky.findIndex((pacovnik) => pacovnik.email === pracovikEmail);
    if (PacovnikIndex === -1) {
      
      return;
    }

    const updatedPracovniky = [...Pracovniky];
    updatedPracovniky.splice(PacovnikIndex, 1);
   
    setPracovniky(updatedPracovniky);
    fetchDataPracovniky();

    } catch (error) {
      console.error('Ошибка при удалении сотрудника:', error);
      console.log('Ошибка при удалении сотрудника:');
      console.log(error);
     
    }
    
  };


  const handleDelete = async (email) => {
    try {
      // Отправляем запрос на сервер для удаления сотрудника по его email
      const response = await fetch('/delete_employee.php', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, firstCompany }), // Передаем только email
      });

      // Проверяем успешность удаления данных на сервере
      if (!response.ok) {
        console.log('Не удалось удалить данные на сервере');
        throw new Error('Не удалось удалить данные на сервере');

      }

      const PacovnikIndex = Pracovniky.findIndex((pacovnik) => pacovnik.email === email);
      if (PacovnikIndex === -1) {

        return;
      }

      const updatedPracovniky = [...Pracovniky];
      updatedPracovniky.splice(PacovnikIndex, 1);  
      setPracovniky(updatedPracovniky);
      fetchDataPracovniky();


    } catch (error) {
      console.error('Ошибка при удалении сотрудника:', error);
      console.log('Ошибка при удалении сотрудника:');
      console.log(error);
    }
  };


  function createDeletingPopUp(employeeEmail) {
    var overlay = document.createElement("div");
    overlay.className = "overlay";

    var popup = document.createElement("div");
    popup.className = "popup";

    var buttonspopup = document.createElement("div");
    buttonspopup.className = "buttonspopup";

    var text = document.createElement("p");
    text.textContent = "Opravdu chcete zaměstnance trvale smazat ?";

    var logoPoUp = document.createElement("img");
    logoPoUp.src = '/static/media/Logo%20OWB%20website.f8178ceeb0449f70be9f.png';
    logoPoUp.classList.add('logoPoUp');

    var yesButton = document.createElement("button");
    yesButton.classList.add('btn', 'btn-primary');
    yesButton.textContent = "Ano";
    yesButton.addEventListener("click", function () {
      //  удаления сотрудника
      handleDelete(employeeEmail);
      closePopUp(overlay, popup);
    });

    var cancelButton = document.createElement("button");
    cancelButton.classList.add('btn', 'btn-danger', 'shadow');
    cancelButton.textContent = "Zrušit";
    cancelButton.addEventListener("click", function () {
      closePopUp(overlay, popup);
    });

    popup.appendChild(logoPoUp);
    popup.appendChild(text);
    buttonspopup.appendChild(yesButton);
    buttonspopup.appendChild(cancelButton);
    popup.appendChild(buttonspopup);
    document.body.appendChild(overlay);
    document.body.appendChild(popup);

    
  }

  function closePopUp(overlay, popup) {
    overlay.remove();
    popup.remove();
    
  }

    // excel export:

const csvlink = {
  data : Pracovniky,
  filename: `Tovarna - ${selectedTovarna.name} - Jobsi export.csv`
}


// END excel export
  
    return ( 
        <>
            <div className="card profilBoxSh">            
                <div className="card-body p-0">
                    <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">   
                        <div className="tbl-caption">
                          <div style={{ display: 'flex' }}>
                            <h4 className="heading mb-0" style={{ marginRight: '20px' }}>{selectedTovarna ? selectedTovarna.name : 'Středisko neni vybrane'}</h4>
                            
                            <p className="text-primary">{selectedTovarna ? selectedTovarna.adress : 'Středisko neni vybrane'}</p>
                            </div>
                            
                            <div className='displayF'>

                            <div>
                                            <CSVLink {...csvlink} className="btn btn-primary light btn-sm csvLink">
                                                <i className="fa-solid fa-file-excel" /> {" "} 
                                                Exportovat do Excelu
                                            </CSVLink>  
                                        </div> 
                                
                                <Link to={"#"} className="btn btn-primary btn-sm" data-bs-toggle="offcanvas"
                                   onClick={()=>tovarna.current.showTovarnaModal()}
                                >+ Zaměstnat</Link> {" "}
                                
                            </div>
                        
                        </div>  
                        <div style={{ display: 'flex', paddingLeft: '20px' }}>
                            <p className="text-primary" style={{  marginRight: '20px' }}><span className="black-color">Tel.:</span> {selectedTovarna ? selectedTovarna.mobile : 'Středisko neni vybrane'}</p>
                            <p className="text-primary" ><span className="black-color">Email: </span> {selectedTovarna ? selectedTovarna.email : 'Středisko neni vybrane'}</p>
                            </div>        
                        <div id="employee-tbl_wrapper" className="dataTables_wrapper no-footer">
                            <table id="projects-tbl" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                <thead>
                                    <tr>
                                        
                                        <th>Jméno</th>
                                        {/* <th>Email</th> */}
                                        <th>Pohlaví</th>
                                        <th>Ubytovna</th>
                                        <th>Národnost</th>
                                        <th>Telefon</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {Pracovniky.slice().reverse().map((pracovnik, index) => (
                                        <tr key={index}>
                                            
                                            <td>
                                                <div className="products">
                                                    
                                                    <div>
                                                        <h6><Link to={`/employee/${pracovnik.mobile}`}>{pracovnik.name}</Link></h6>
                                                        
                                                    </div>	
                                                </div>
                                            </td>
                                            
                                                
                                            {/* <td><Link to={"#"} className="text-primary">{pracovnik.email}</Link></td> */}
                                            <td>
                                                <span>{pracovnik.gender}</span>
                                            </td>
                                            <td>
  {pracovnik.ubytovna !== "-" ? (
    <span className="badge badge-success light border-0">{pracovnik.ubytovna}</span>
  ) : (
    <span className="badge badge-danger light border-0">Neubytovan</span>
  )}
</td>
<td>
                                                <span>{pracovnik.country}</span>
                                            </td>		
                                            <td>
                                                <span>{pracovnik.mobile}</span>
                                            </td>

                                            <td>
                          <div className="d-flex" style={{justifyContent: 'flex-end'}} >  
                           
                            <Link
                              to={"#"}
                              className="btn btn-danger shadow sharp noRoomOrange"
                              onClick={() => handleDeletePracovnik(pracovnik.email)}
                            >
                              {/* <i className="fa fa-trash"></i> */}
                              Ukončit
                            </Link>
                          </div>
                        </td>

                        <td>
                          <div className="d-flex" style={{justifyContent: 'flex-end'}} >  
                            
                            <Link
                              to={"#"}
                              className="btn btn-danger shadow sharp"
                              onClick={() => createDeletingPopUp(pracovnik.email)}
                            >
                              
                              Smazat
                            </Link>
                          </div>
                        </td>

                                        </tr>
                                    ))}
                                </tbody>                                
                            </table>
                            <div className="d-sm-flex text-center justify-content-between align-items-center">
                                <div className='dataTables_info'>
                                    {/* Showing {lastIndex-recordsPage + 1} to{" "}
                                    {tableData.length < lastIndex ? tableData.length : lastIndex}
                                    {" "}of {tableData.length} entries */}
                                </div>
                                {/* <div
                                    className="dataTables_paginate paging_simple_numbers justify-content-center"
                                    id="example2_paginate"
                                >
                                    <Link
                                        className="paginate_button previous disabled"
                                        to="#"                                        
                                        onClick={prePage}
                                    >
                                        <i className="fa-solid fa-angle-left" />
                                    </Link>
                                    <span>                                      
                                        {number.map((n , i )=>(
                                            <Link className={`paginate_button ${currentPage === n ? 'current' :  '' } `} key={i}                                            
                                                onClick={()=>changeCPage(n)}
                                            > 
                                                {n}  
                                            </Link>
                                        ))}
                                    </span>
                                    <Link
                                        className="paginate_button next"
                                        to="#"                                        
                                        onClick={nextPage}
                                    >
                                        <i className="fa-solid fa-angle-right" />
                                    </Link>
                                </div> */}
                            </div> 
                        </div>
                    </div>
                </div>
            </div>           
            <PracovnikyOffcanvas 
                ref={tovarna}
                Title="Zamestnat"
                setDataUpdateTrigger={setDataUpdateTrigger}
                selectedTovarna={selectedTovarna}
            />

        
        </>
    );
};


export default ProfileTovarnaTableList;