import React, { useState, forwardRef, useImperativeHandle, useEffect  } from 'react';
import {Link} from 'react-router-dom';
import { Offcanvas } from 'react-bootstrap';
import axios from 'axios';


const AddNewCompany = forwardRef((props, ref) => {

  const { LoginUser, setDataUpdateTrigger } = props;



    // const { setDataUpdateTrigger } = props;
    const [AddNewC, setAddNewC] = useState(false);


    const showAddNewCModal = () => {
      setAddNewC(true);
    };
  
    useImperativeHandle(ref, () => ({
      showAddNewCompanyModal() { 
        setAddNewC(true);
      },
      showAddNewCModal, // Передаем метод в ref
    }));
  
  
  
    const ComHandleSubmit = async (e) => {
      e.preventDefault(); 
      

      const formData = new FormData(e.target);

      formData.append('user', LoginUser.email);

      console.log('formData', formData);

      formData.forEach((value, key) => {
        console.log(`${key}: ${value}`);
      }); 
  
        axios.post('/newScript.php', formData) 
            .then(response => {
              setDataUpdateTrigger(prev => !prev);
            })
            .catch(error => {
                console.error(error);
          });

  
        setAddNewC(false);
 
    };
      

    return (
        <>
            <Offcanvas show={AddNewC} onHide={setAddNewC} className="offcanvas-end customeoff" placement='end'>
				<div className="offcanvas-header">
              
					<h5 className="modal-title" id="#gridSystemModal">Přidat pobočku</h5>
					<button type="button" className="btn-close" 
						onClick={()=>setAddNewC(false)}
					>
						<i className="fa-solid fa-xmark"></i>
					</button>
				</div>
				<div className="offcanvas-body">
                    <div className="container-fluid">
                        
                        <form onSubmit={ComHandleSubmit}>
                            <div className="row">
           
                            <div className="col-xl-6 mb-3">
                                    <label htmlFor="exampleFormControlInput99" className="form-label">Název pobočky<span className="text-danger">*</span></label>                                    
                                    <input
                                      type="text"
                                      id="addNewCompany"
                                      name="addNewCompany"
                                      className="inputReg form-control"
                                    />

                                    {/* <input type="hidden" name="company_id" value={companyId} /> */}

                                </div>

                            </div>
                            <div>
                                <button type="submit" className="btn btn-primary me-1">Přidat</button>
                                <Link to={"#"} onClick={()=>setAddNewC(false)} className="btn btn-danger light ms-1">Zrušit</Link>
                            </div>
                        </form>
                    </div>
				</div>
			</Offcanvas>     
        </>
    );
});

export default AddNewCompany;