import React, {useState, useRef, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Link} from 'react-router-dom';
import { IMAGES } from '../../../constant/theme';
import HostelsOffcanvas from '../../../constant/HostelsOffcanvas'; 
import EditHostelsOffcanvas from '../../../constant/EditHostelsOffcanvas';
import { setHostelData } from '../../../../store/actions/HostelActions';
import { CSVLink } from 'react-csv';
 
const HostelsTableList = ({setEventsUpdateTrigger}) => {
  const firstCompany = useSelector(state => (state.company && state.company.firstCompany) || '');
  var activeCompany = document.cookie.replace(/(?:(?:^|.*;\s*)activeCompany\s*\=\s*([^;]*))/, "$1");
    const dispatch = useDispatch();

    const handleLinkClick = (hostel) => {
    dispatch(setHostelData(hostel));
  };

    const [dataUpdateTrigger, setDataUpdateTrigger] = useState(false);
    const [selectedHostel, setSelectedHostel] = useState(null); 
    const invite = useRef();
    const hostel = useRef();   
    const editHostel = useRef(); 
    const [hostels, setHostels] = useState([]);


  useEffect(() => {
    fetchData77();
  }, [firstCompany, dataUpdateTrigger]);


  const fetchData77 = async () => {
    try {
      const response = await fetch(`/${firstCompany}/ubytovny.json?${Date.now()}`);
      const data = await response.json();
      setHostels(data);
    } catch (error) {
      console.error('Ошибка при загрузке данных:', error);
    }
  };

  const fetchDataSetEmp = async () => {
    try {
      const response = await fetch(`/${firstCompany}/file.json?${Date.now()}`);
      const data = await response.json();
   
      setClients(data);


    } catch (error) {
      console.error('Ошибка при загрузке данных:', error);
    }
  };

  const [Rooms, setRooms] = useState([]);
  const [clients, setClients] = useState([]);
  const [hostelBedInfo, setHostelBedInfo] = useState({});
  
  useEffect(() => {
    fetchDataSetEmp();
    fetchDataRooms();
  }, [firstCompany]); 
  
  const fetchDataRooms = async () => {
    try {
      const response = await fetch(`/${firstCompany}/pokoje.json?${Date.now()}`);
      const data = await response.json();
      setRooms(data);
    } catch (error) {
      console.error('Ошибка при загрузке данных:', error);
    }
  };
  
  

useEffect(() => {
const updatedHostelBedInfo = {};
Rooms.forEach((room) => {
const { hostelId, quantity } = room;
if (!updatedHostelBedInfo[hostelId]) {
  updatedHostelBedInfo[hostelId] = {
    totalBeds: 0,
    occupiedBeds: 0,
  };
}
updatedHostelBedInfo[hostelId].totalBeds += parseInt(quantity, 10);
});

clients.forEach((client) => {
const { hostelId, room } = client;
if (updatedHostelBedInfo[hostelId] && room !== '-' && room !== null) {
  updatedHostelBedInfo[hostelId].occupiedBeds += 1;
}
});
console.log('updatedHostelBedInfo - HT', updatedHostelBedInfo)
setHostelBedInfo(updatedHostelBedInfo);
}, [Rooms, clients]);

  


  const handleDelete = async (id) => {
     
   
    try {
      // Отправляем запрос на сервер для удаления сотрудника по его name
      const response = await fetch('https://online.jobsi.app/delete_hostel.php', { 
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id, firstCompany }), // Передаем только name
      });
      
      // Проверяем успешность удаления данных на сервере
      if (!response.ok) {
        console.log('Не удалось удалить данные на сервере');
        throw new Error('Не удалось удалить данные на сервере');
        
      }
    
      const hostelIndex = hostels.findIndex((hostel) => hostel.id === id);
    if (hostelIndex === -1) {
      
      return;
    }

    const updatedHostels = [...hostels];
    updatedHostels.splice(hostelIndex, 1);
    setHostels(updatedHostels);

    // new Deleting: 

    const response2 = await fetch('https://online.jobsi.app/delete_hostelAndRooms.php', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id, firstCompany }), // Передаем только name
      });

    // end

    // new Deleting in pokoje.json:

    const response3 = await fetch('https://online.jobsi.app/delete_hostelRoomsInPokojeJson.php', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id, firstCompany }), // Передаем только name
      });

    // end


    } catch (error) {
      console.error('Ошибка при удалении сотрудника:', error);
      console.log('Ошибка при удалении сотрудника:');
      console.log(error);
    }
  };
  
  
  const handleEdit = (hostel) => {
    
    setSelectedHostel(hostel); // Устанавливаем выбранного работника для редактирования
    editHostel.current.showeditHostelModal();
  };


  function createDeletingPopUpUbytovna(hostelName) {
    
    var overlay = document.createElement("div");
    overlay.className = "overlay";
  
    
    var popup = document.createElement("div");
    popup.className = "popup"; 

    var buttonspopup = document.createElement("div");
    buttonspopup.className = "buttonspopup";
  
    
    var text = document.createElement("p");
    text.textContent = "Opravdu chcete ubytovnu trvale smazat ?";

    var logoPoUp = document.createElement("img");
    logoPoUp.src = '/static/media/Logo%20OWB%20website.f8178ceeb0449f70be9f.png';
    logoPoUp.classList.add('logoPoUp');
    
    var yesButton = document.createElement("button");  
    yesButton.classList.add('btn', 'btn-primary');
    yesButton.textContent = "Ano";
    yesButton.addEventListener("click", function () {
      //  удаление
      handleDelete(hostelName);
      closePopUp(overlay, popup);
    });
  
    
    var cancelButton = document.createElement("button"); 
    cancelButton.classList.add('btn', 'btn-danger', 'shadow');
    cancelButton.textContent = "Zrušit";
    cancelButton.addEventListener("click", function () {
      closePopUp(overlay, popup);
    });
  
    
    popup.appendChild(logoPoUp);
    popup.appendChild(text);
    buttonspopup.appendChild(yesButton);
    buttonspopup.appendChild(cancelButton);
    popup.appendChild(buttonspopup);
    document.body.appendChild(overlay);
    document.body.appendChild(popup);

    setTimeout(() => {
      
    },20000);

    // const timerPopUp = setTimeout(function() {
    //   overlay.remove();
    //   popup.remove();
    // }, 20000);

  }
  
  function closePopUp(overlay, popup) {
    overlay.remove();
    popup.remove();
    // clearTimeout(timerPopUp);
  }


  const filterHostelNameTable = useRef(null);
  const hostelsTableRef = useRef(null);

  useEffect(() => {
    const filterNameTable = () => {
      const filterInput = filterHostelNameTable.current;
      const hostelsTable = hostelsTableRef.current;

      if (!filterInput || !hostelsTable) {
        return; // Защита от ошибок, если элементы не существуют
      }

      const rows = hostelsTable.getElementsByTagName('tr');
      const filterText = filterInput.value.toUpperCase();

      for (let i = 1; i < rows.length; i++) {
        const name = rows[i].getElementsByTagName('td')[0].textContent;
        if (name.toUpperCase().startsWith(filterText)) {
          rows[i].style.display = '';
        } else {
          rows[i].style.display = 'none';
        }
      }
    };

    // Назначаем обработчик события 'input' для фильтрации
    filterHostelNameTable.current.addEventListener('input', filterNameTable);

    // Выполняем начальную фильтрацию
    filterNameTable();
  }, []);

// excel export:

const csvlink = {
  data : hostels,
  filename: "Ubytovny - Jobsi export.csv"
}


// END excel export


    return (
        <>
            <div className="card">            
                <div className="card-body p-0">
                    <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">   
                        <div className="tbl-caption">
                            <h4 className="heading mb-0 heading-img">Ubytovny - {hostels.length}</h4>
                            <input
  type="text"
  ref={filterHostelNameTable}
  placeholder="Zadejte název"
  className="NameSearchInput"
/>
                            <div className='displayF'>
                            <div>
                                            <CSVLink {...csvlink} className="btn btn-primary light btn-sm csvLink">
                                                <i className="fa-solid fa-file-excel" /> {" "} 
                                                Exportovat do Excelu
                                            </CSVLink>  
                                        </div> 
                                
                                <Link to={"#"} className="btn btn-primary btn-sm" data-bs-toggle="offcanvas"
                                   onClick={()=>hostel.current.showEmployeModal()}
                                >+ Přidat ubytovnu</Link> {" "}
                                
                            </div> 
                        
                        </div>          
                        <div id="employee-tbl_wrapper" className="dataTables_wrapper no-footer">
                            <table id="projects-tbl hostelsTable" className="table ItemsCheckboxSec dataTable no-footer mb-0" ref={hostelsTableRef}>
                                <thead>
                                    <tr>
                                        
                                        <th>Název ubytovny</th>
                                        <th>Obsazenost</th>
                                        <th>Adresa</th>
                                        <th>Kontaktní telefon</th>
                                        <th>Kontaktní email</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {hostels.slice().reverse().map((hostel, index) => (
                                        <tr key={index}>
                                            {/* <td className="sorting_20">
                                                <div className="form-check11custom-checkbox">
                                                    <input type="checkbox" className="form-check-input" 
                                                        id={`emply-${item.id}`}
                                                        checked={item.inputchecked}
                                                        onChange={()=>handleChecked(item.id)}
                                                    />
                                                    <label className="form-check-label" htmlFor={`emply-${item.id}`}></label>
                                                </div>
                                            </td> */}
                                            {/* <td><span>{item.emplid}</span></td> */}
                                            <td>
                                                <div className="products">
                                                    {/* <img src={item.image} className="avatar avatar-md" alt="" /> */}
                                                    <div>
                                                        <h6><Link to={`/profile-hostels/${hostel.id}`} onClick={() => handleLinkClick(hostel)}>{hostel.name}</Link></h6>
                                                         
                                                    </div>	
                                                </div>
                                            </td>
                                            <td>
                                              {hostelBedInfo && hostelBedInfo[hostel.id] && (

                                            <div className="beds-container">
        <div className="progress">
          <div className="progress-bar"
               role="progressbar"
               style={{ width: `${(((hostelBedInfo[hostel.id].occupiedBeds) / (hostelBedInfo[hostel.id].totalBeds)) * 100).toFixed(0)}%`, backgroundColor: (((hostelBedInfo[hostel.id].occupiedBeds) / (hostelBedInfo[hostel.id].totalBeds)) * 100).toFixed(0) >= 90
               ? 'RGBA(58, 201, 119)'
               : (((hostelBedInfo[hostel.id].occupiedBeds) / (hostelBedInfo[hostel.id].totalBeds)) * 100).toFixed(0) >= 50
               ? 'RGBA(255, 159, 0)'
               : 'RGBA(255, 79, 102)' }}
               aria-valuenow={(((hostelBedInfo[hostel.id].occupiedBeds) / (hostelBedInfo[hostel.id].totalBeds)) * 100).toFixed(0)}
               aria-valuemin="0"
               aria-valuemax="100">
                {(((((hostelBedInfo[hostel.id].occupiedBeds) / (hostelBedInfo[hostel.id].totalBeds)) * 100).toFixed(0)) >= 33) && ( 
    <>{(((hostelBedInfo[hostel.id].occupiedBeds) / (hostelBedInfo[hostel.id].totalBeds)) * 100).toFixed(0)}%</>
)}

          </div>
        </div>
        {(((((hostelBedInfo[hostel.id].occupiedBeds) / (hostelBedInfo[hostel.id].totalBeds)) * 100).toFixed(0)) <= 32) && ( 
        <p className='malaObsaz'>{(((hostelBedInfo[hostel.id].occupiedBeds) / (hostelBedInfo[hostel.id].totalBeds)) * 100).toFixed(0)}%</p>
        )}
      </div> 
                                              )}
                                              {(!hostelBedInfo || !hostelBedInfo[hostel.id]) && (
    <div className="beds-container">
    <div className="progress">
      <div className="progress-bar"
           role="progressbar"
           style={{ width: `${0}%`, backgroundColor: 0 }}
           aria-valuenow={0}
           aria-valuemin="0"
           aria-valuemax="100">
        
      </div>
    </div>
    <p className='malaObsaz'>0%</p>
  </div> 
)}
                                            </td>
                                            <td><Link to={"#"} className="text-primary">{hostel.adress}</Link></td>
                                            <td>
                                                <span>{hostel.mobile}</span>
                                            </td>
                                            <td>
                                                <span>{hostel.email}</span>
                                            </td>
                                            
                                            <td>
                          <div className="d-flex">
                            <Link
                              to={"#"}
                              className="btn btn-primary shadow btn-xs sharp me-1" data-bs-toggle="offcanvas" onClick={() => handleEdit(hostel)}
                            >
                              <i className="fas fa-pencil-alt"></i>
                            </Link>  
                            <Link
                              to="#"
                              className="btn btn-danger shadow btn-xs sharp"
                              onClick={() => {
                                  createDeletingPopUpUbytovna(hostel.id); 
                                  console.log('HostelsTableList -- hostel.id', hostel.id); 
                                  console.log('HostelsTableList -- hostel', hostel);
                              }}
                          >
                              <i className="fa fa-trash"></i>
                            </Link>
                          </div>
                        </td>
                                        </tr>
                                    ))}
                                </tbody>                                
                            </table>
                            <div className="d-sm-flex text-center justify-content-between align-items-center">
                                <div className='dataTables_info'>
                                    {/* Showing {lastIndex-recordsPage + 1} to{" "}
                                    {tableData.length < lastIndex ? tableData.length : lastIndex}
                                    {" "}of {tableData.length} entries */}
                                </div>
                                {/* <div
                                    className="dataTables_paginate paging_simple_numbers justify-content-center"
                                    id="example2_paginate"
                                >
                                    <Link
                                        className="paginate_button previous disabled"
                                        to="#"                                        
                                        onClick={prePage}
                                    >
                                        <i className="fa-solid fa-angle-left" />
                                    </Link>
                                    <span>                                      
                                        {number.map((n , i )=>(
                                            <Link className={`paginate_button ${currentPage === n ? 'current' :  '' } `} key={i}                                            
                                                onClick={()=>changeCPage(n)}
                                            > 
                                                {n}  
                                            </Link>
                                        ))}
                                    </span>
                                    <Link
                                        className="paginate_button next"
                                        to="#"                                        
                                        onClick={nextPage}
                                    >
                                        <i className="fa-solid fa-angle-right" />
                                    </Link>
                                </div> */}
                            </div> 
                        </div>
                    </div>
                </div>
            </div>           
            <HostelsOffcanvas 
                ref={hostel}
                Title="Add Hostel"
                setDataUpdateTrigger={setDataUpdateTrigger}
                setEventsUpdateTrigger={setEventsUpdateTrigger}
            />
            <EditHostelsOffcanvas 
                ref={editHostel}
                Title="Edit Hostel"
                setDataUpdateTrigger={setDataUpdateTrigger}
                setEventsUpdateTrigger={setEventsUpdateTrigger}
                selectedHostel={selectedHostel}
            />
            {/* <InviteCustomer
                ref={invite}     
                Title="Invite Employee"
            /> */}
        
        </>
    );
};


export default HostelsTableList;