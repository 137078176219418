import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Pagination = ({ totalEmployees, employeesPerPage, paginate }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const pageNumbers = [];

  useEffect(() => {
    setCurrentPage(1); // Сброс currentPage при изменении totalEmployees или employeesPerPage
  }, [totalEmployees, employeesPerPage]);

  if (totalEmployees > 0 && employeesPerPage > 0) {
    for (let i = 1; i <= Math.ceil(totalEmployees / employeesPerPage); i++) {
      pageNumbers.push(i);
    }
  }

  const firstEmployeeIndex = employeesPerPage * (currentPage - 1) + 1;
  const lastEmployeeIndex = Math.min(totalEmployees, employeesPerPage * currentPage);

  const handlePagination = (pageNumber) => {
    if (pageNumber < 1 || pageNumber > Math.ceil(totalEmployees / employeesPerPage)) {
      return; // Не обрабатываем недопустимые страницы
    }
    setCurrentPage(pageNumber); // Устанавливаем currentPage при клике на кнопку
    paginate(pageNumber);
  };

  return (
    <div className="d-sm-flex text-center justify-content-between align-items-center">
      <div className="dataTables_info">
        Zobrazeno {firstEmployeeIndex} - {lastEmployeeIndex} z {totalEmployees} zaměstnanců
      </div>
      <div
        className="dataTables_paginate paging_simple_numbers justify-content-center"
        id="example2_paginate"
      >
        <Link
          className={`paginate_button previous ${currentPage === 1 ? "disabled" : ""}`}
          to="#"
          onClick={() => handlePagination(currentPage - 1)}
        >
          <i className="fa-solid fa-angle-left" />
        </Link>
        <span>
          {pageNumbers.map((number, index) => (
            <Link
              key={index}
              className={`paginate_button ${number === currentPage ? "current" : ""}`}
              to="#"
              onClick={() => handlePagination(number)}
            >
              {number}
            </Link>
          ))}
        </span>
        <Link
          className={`paginate_button next ${currentPage === pageNumbers[pageNumbers.length - 1] ? "disabled" : ""}`}
          to="#"
          onClick={() => handlePagination(currentPage + 1)}
        >
          <i className="fa-solid fa-angle-right" />
        </Link>
      </div>
    </div>
  );
};

export default Pagination;
