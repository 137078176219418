import React,{ useEffect, useState} from 'react';
import { useSelector } from 'react-redux';

//Import Components
import MainPagetitle from '../../layouts/MainPagetitle';
import EmployeesTableList from './elements/EmployeesTableList';
import AllProjectDonutChart from './elements/AllProjectDonutChart';
import HostelsTableList from './elements/HostelsTableList';
import TovarnyTableList from './elements/TovarnyTableList';
import ProjectStatusBlog from '../Dashboard/elements/ProjectStatusBlog';
import Projects from '../Dashboard/Projects';
import { SVGICON } from "../../constant/theme";
import {Link } from 'react-router-dom'
import FullCalendar from '@fullcalendar/react';
import daygridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import moment from 'moment';
import EventCalendar from './EventCalendar';
import DonutChart from "./../../pages/WidgetBasic/DonutChart";
import CountUp from 'react-countup';

const Home = () => {
        const firstCompany = useSelector(state => (state.company && state.company.firstCompany) || '');
        const [windowWidth, setWindowWidth] = useState(window.innerWidth);
        const [employesNoWork, setEmployesNoWork] = useState([]);
        const [employesWork, setEmployesWork] = useState([]);
        const [employesAll, setEmployesAll] = useState([]);
        const [hotels, setHotels] = useState([]);
        const [strediskaGraf, setStrediskaGraf] = useState([]);
        const [strediskoCounts, setStrediskoCounts] = useState({});
        const [employesNoLive, setEmployesNoLive] = useState([]);
        const [employesLive, setEmployesLive] = useState([]);
        const [countEmployes, setcountEmployes] = useState([]);
        const [data, setData] = useState([]);
        const [eventsUpdateTrigger, setEventsUpdateTrigger] = useState(false);
        const [LastEvents, setLastEvents] = useState([]);
        const monthNames = ['Led', 'Ún', 'Bře', 'Dub', 'Kvě', 'Čvn', 'Čvc', 'Srp', 'Zář', 'Říj', 'Lis', 'Pro'];      

       
if (!document.cookie.includes('user_id')) {
 
  window.location.href = 'https://online.jobsi.app/login';
}


        useEffect(() => {
          fetchDataSetEmp();
          fetchDataSetTovarny();
          fetchDataEvents();
          fetchHotels();

        }, [firstCompany]); 
      
        useEffect(() => {
          fetchDataEvents();
          console.log('eventsUpdateTrigger -- EC', eventsUpdateTrigger);
        }, [eventsUpdateTrigger]);

        useEffect(() => {
          
          const updatedCounts = {};
          employesAll.forEach((employee) => {
            const stredisko = employee.stredisko;
            updatedCounts[stredisko] = (updatedCounts[stredisko] || 0) + 1;
          });
          console.log('employesAll', updatedCounts);
          setStrediskoCounts(updatedCounts);
          console.log('setStrediskoCounts - updatedCounts', updatedCounts);
        }, [employesAll, strediskaGraf]);
      
        const fetchDataSetEmp = async () => {
          try {
            const response = await fetch(`/${firstCompany}/file.json?${Date.now()}`);
            const data = await response.json();
            setEmployesAll(data);
            setClients(data);
                
            let countStrediskoWithDash = 0;

            for (const entry of data) {
              if (entry["stredisko"] === "-") {
                countStrediskoWithDash++;
              }
            }

            const totalCount = data.length;
      
            let EmpWork = totalCount - countStrediskoWithDash;
            setEmployesWork(EmpWork);
            setEmployesNoWork(countStrediskoWithDash);

            let count = 0;

            for (const item of data) {
              if (item.roomID !== "-" && item.roomID !== null && item.roomID !== undefined) {
                count++;
              } 
            }

            setEmployesLive(count);

            
            const noLive = totalCount - count ;
            
            setEmployesNoLive(noLive);
            

          } catch (error) {
            console.error('Ошибка при загрузке данных:', error);
          }
        };

        const fetchHotels = async () => {
          try {
            const ubytovnyJson = await fetch(`/${firstCompany}/ubytovny.json?${Date.now()}`);
            const ubytovny = await ubytovnyJson.json();
            setHotels(ubytovny);
          } catch (error) {
            console.error('Ошибка при загрузке данных:', error);
          }
        };
      
        const fetchDataSetTovarny = async () => {
          try {
            const response = await fetch(`/${firstCompany}/tovarny.json?${Date.now()}`);
            const data = await response.json();
            setStrediskaGraf(data);
            console.log('setStrediskaGraf - data', data);
          } catch (error) {
            console.error('Ошибка при загрузке данных:', error);
          }
        };

        const fetchDataEvents = async () => {
          try {
            const response = await fetch(`/${firstCompany}/events.json?${Date.now()}`);
            const data = await response.json();
            setLastEvents(data.reverse());
          } catch (error) {
            console.error('Ошибка при загрузке данных:', error);
          }
        };

        // Check if Logged in
          
    //     function checkCookie() {
    //       var userIdCookie = getCookie('user_id');
    //       if (userIdCookie === '') {
           

            
    //       } 
    //   }
  
    // function getCookie(cookieName) {
    //     var name = cookieName + '=';
    //     var decodedCookie = decodeURIComponent(document.cookie);
    //     var cookieArray = decodedCookie.split(';');
    //     for (var i = 0; i < cookieArray.length; i++) {
    //         var cookie = cookieArray[i].trim();
    //         if (cookie.indexOf(name) === 0) {
    //             return cookie.substring(name.length, cookie.length);
    //         }
    //     }
    //     return '';
    // }
    
   
    // checkCookie();
          
      
      // Check if Logged in   END


        // START Grafy ubytoven:
        // 
        // 
        // 

        

        const [Rooms, setRooms] = useState([]);
        const [clients, setClients] = useState([]);
        const [hostelBedInfo, setHostelBedInfo] = useState({});
        
        useEffect(() => {
          fetchDataSetEmp();
          fetchDataRooms();
        }, [firstCompany]); 
        
        const fetchDataRooms = async () => {
          try {
            const response = await fetch(`/${firstCompany}/pokoje.json?${Date.now()}`);
            const data = await response.json();
            setRooms(data);
          } catch (error) {
            console.error('Ошибка при загрузке данных:', error);
          }
        };
        
        

useEffect(() => {
    const updatedHostelBedInfo = {};
    Rooms.forEach((room) => {
      const { hostelId, quantity } = room;
      if (!updatedHostelBedInfo[hostelId]) {
        updatedHostelBedInfo[hostelId] = {
          totalBeds: 0,
          occupiedBeds: 0,
        };
      }
      updatedHostelBedInfo[hostelId].totalBeds += parseInt(quantity, 10);
    });

    clients.forEach((client) => {
      const { hostelId, room } = client;
      if (updatedHostelBedInfo[hostelId] && room !== '-' && room !== null) {
        updatedHostelBedInfo[hostelId].occupiedBeds += 1;
      }
    });

    setHostelBedInfo(updatedHostelBedInfo);

    // notifications code: d. Если в отеле больше 20 дней есть >= 20% пустых кроватей: ...% empty beds in hotel for more than 20 days:

    // Создаем объект для хранения сумм количества кроватей по hostelId
const hostelBeds = {};

// Проходим по каждому объекту в массиве
Rooms.forEach(room => {
    const hostelId = room.hostelId;
    const quantity = parseInt(room.quantity);
    
    // Если hostelId уже есть в объекте, добавляем к существующему значению
    if (hostelBeds[hostelId]) {
        hostelBeds[hostelId] += quantity;
    } else {
        // Иначе, инициализируем новое значение
        hostelBeds[hostelId] = quantity;
    }
});

// Преобразуем объект в требуемый массив объектов
const allBeds = Object.keys(hostelBeds).map(hostelId => ({
    hostelId: hostelId,
    totalBeds: hostelBeds[hostelId]
}));

const hostelCounts = {};

// Проверяем, что массив clients не пуст
if (clients && clients.length > 0) {
    // Проходим по каждому объекту в массиве
    clients.forEach(person => {
        const hostelId = person.hostelId;
        const ubytovna = person.ubytovna;

        // Если hostelId уже есть в объекте, увеличиваем счетчик на 1
        if (hostelCounts[hostelId]) {
            hostelCounts[hostelId].quantity += 1;
        } else {
            // Иначе, инициализируем новое значение счетчиком 1 и добавляем ubytovna
            hostelCounts[hostelId] = {
                quantity: 1,
                ubytovna: ubytovna
            };
        }
    });
}

// Проверяем, что hostelCounts не пуст
if (Object.keys(hostelCounts).length > 0) {
    // Преобразуем объект в требуемый массив объектов
    const occupiedBeds = Object.keys(hostelCounts).map(hostelId => ({
        hostelId: hostelId,
        ubytovna: hostelCounts[hostelId].ubytovna, // Используем значение ubytovna
        quantity: hostelCounts[hostelId].quantity
    }));

    const bedsInfo = {};

    allBeds.forEach(bed => {
        bedsInfo[bed.hostelId] = {
            totalBeds: bed.totalBeds,
            occupiedBeds: 0
        };
    });

    // Обновляем количество занятых кроватей в bedsInfo
    occupiedBeds.forEach(bed => {
        if (bedsInfo[bed.hostelId]) {
            bedsInfo[bed.hostelId].occupiedBeds = bed.quantity;
        }
    });

    console.log("Home - occupiedBeds", occupiedBeds);
    

    const currentTime = new Date().toISOString();

    // Вычисляем процент пустых кроватей и фильтруем отели с >= 20% пустых кроватей
    const lessThan20Beds = Object.keys(bedsInfo).map(hostelId => {
        const totalBeds = bedsInfo[hostelId].totalBeds;
        const occupiedBedsCount = bedsInfo[hostelId].occupiedBeds;
        const emptyBeds = totalBeds - occupiedBedsCount;
        const emptyPercentage = (emptyBeds / totalBeds) * 100;

        // Находим соответствующий объект из occupiedBeds для получения ubytovna
        // const matchingBed = occupiedBeds.find(bed => bed.hostelId === hostelId);
        // const ubytovna = matchingBed ? matchingBed.ubytovna : '';
        
        console.log("hotels", hotels);
        const matchingBed = hotels.find(hotel => hotel.id === hostelId);
        console.log("matchingBed", matchingBed);
        const ubytovna = matchingBed ? matchingBed.name : '';
        console.log("ubytovna", ubytovna);

        return {
            hostelId: hostelId,
            emptyPercentage: emptyPercentage,
            currentTime: currentTime,
            firstCompany: firstCompany,
            ubytovna: ubytovna
        };
    }).filter(hotel => hotel.emptyPercentage >= 20);

    console.log("Home - lessThan20Beds", lessThan20Beds);

    async function sendData(data) {
      console.log("Home - sendData data", data);
        const url = `https://online.jobsi.app/notifLessThan20B.php`;

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                const errorText = await response.text(); // Получаем текст ответа
                throw new Error('Network response was not ok: ' + errorText);
            }

            const responseData = await response.json();
            console.log('Success:', responseData);
        } catch (error) {
            console.error('Error:', error);
        }
    }

    // Отправляем данные
    sendData(lessThan20Beds); 
} else {
    console.error('Error: hostelCounts is empty.');
}

}, [Rooms, clients]);

async function sendData(data) {
  const url = `https://online.jobsi.app/notifLessThan20B.php`;

  try {
      const response = await fetch(url, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
      });

      if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
      }

      const responseData = await response.json();
      console.log('Success:', responseData);
  } catch (error) {
      console.error('Error:', error);
  }
}

        
        const percentages = Object.keys(hostelBedInfo).map((hostelId) => {
          console.log('percentages', percentages);
          const totalBeds = hostelBedInfo[hostelId].totalBeds;
          const occupiedBeds = hostelBedInfo[hostelId].occupiedBeds;
          const occupancyRate = ((occupiedBeds / totalBeds) * 100).toFixed(0);
          console.log('occupiedBeds',occupiedBeds); 
          console.log('totalBeds',totalBeds); 
          console.log('occupancyRate',occupancyRate); 
          // const freeBeds = totalBeds - occupiedBeds;
          // const percentageFree = ((freeBeds / totalBeds) * 100).toFixed(0);
          return { hostelId, occupancyRate };
        });
        
        percentages.sort((a, b) => a.occupancyRate - b.occupancyRate);

        // percentages.sort((a, b) => {
        //   return b.percentageFree - a.percentageFree;
        // });



        // 
        // 
        // 
        // END Grafy ubytoven


	return(
		<>			
    {/* ${SVGICON.CoreHr.path} */}
			<MainPagetitle mainTitle={`Kontrolní panel`} pageTitle="Kontrolní panel" parentTitle="Home" />
			<div className="container-fluid homePage1">
      <div className="container-new"> 
      {/* <div className="col-xl-3 col-sm-6 col-height"> */}
          <div className="col-xl-2-5 col-height fix-width-300">
                <div className="card same-card noOnMobile">
                  
                  <EventCalendar
                  LastEvents={LastEvents}
                  setEventsUpdateTrigger={eventsUpdateTrigger}
                  />
                  <div className="events">
                        <h6>POSLEDNÍ ÚDALOSTI</h6>
                        <div className="dz-scroll event-scroll">
                        {LastEvents.slice(0, 5).map((LastEvent, index) => {
                        const eventDate2 = new Date(LastEvent.datetime);
                        const monthAbbreviation2 = monthNames[eventDate2.getMonth()].slice(0, 3);
                              return (
                                <div className="event-media" key={index}>
                                    <div className="d-flex align-items-center">
                                        <div className="event-box">
                                            <h5 className="mb-0">{eventDate2.getDate()}</h5>
                                            <span>{monthAbbreviation2}</span>
                                        </div>
                                        <div className="event-data ms-2">
                                            <h5 className="mb-0"><Link to={"#"}>{LastEvent.description}</Link></h5>
                                            {/* <span>w3it Technologies</span> */}
                                        </div>
                                    </div>
                                    <span className="text-secondary">{LastEvent.datetime.slice(11, 16)}</span>
                                </div>
                              );
                            })}                           
                        </div>	
                    </div>
                </div>
                {/* <div className="card same-card">
                    <div className="card-body d-flex align-items-center  py-2">                        
                        <AllProjectDonutChart
						employesWork={employesWork}
                        employesNoWork={employesNoWork}
                        employesLive={employesLive}
                        employesNoLive={employesNoLive}
                        employesAll={employesAll}
						 />
                        <ul className="project-list">
                            <li><h6>Pracovníky</h6></li>
                            <li>
                                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="10" height="10" rx="3" fill="#3AC977"/>
                                </svg>{" "}
                                Pracuje - {employesWork}
                            </li>
                            <li>
                                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="10" height="10" rx="3" fill="var(--primary)"/>
                                </svg>{" "}
                                Nepracuje - {employesNoWork}
                            </li>
                            <li>
                                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="10" height="10" rx="3" fill="green"/>
                                </svg>{" "}
                                Ubytovano - {employesLive}
                            </li>
                            <li>
                                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="10" height="10" rx="3" fill="#0043c1"/>
                                </svg>{" "}
                                Neubytovano - {employesNoLive}
                            </li>
                        </ul>
                    </div>
                </div> */}
{console.log('employesWork', employesWork)}
{Object.keys(strediskaGraf).length > 0 && Object.keys(strediskoCounts).length > 0 && Object.keys(employesAll).length > 0 && employesWork && (
  <ProjectStatusBlog 
    strediskaGraf={strediskaGraf}
    strediskoCounts={strediskoCounts}
    employesAll={employesAll}
    employesWork={employesWork}
  />
)}



                <div className="card borderNone">
              <div className="card-body padding0">	
                <div className="students1 d-flex  justify-content-between flex-wrap flexBottom">


                
                {
  percentages.slice(0, 4).map((item, index) => {
    const hostelName = Rooms.find(room => room.hostelId === item.hostelId)?.ubytovna;
    console.log('hostelName', hostelName);
    return (
    
    <div className="col-xl-4 col-md-6 width100" key={index}>
      <div className="card box-hover cartFlexRight marginB10">
        <div className="card-body cartBodyFlexRight">
          <div>
            <div className="d-inline-block position-relative donut-chart-sale margin0">
              <DonutChart
                value={item.occupancyRate}
                backgroundColor={
                  item.occupancyRate >= 90
                    ? 'RGBA(58, 201, 119)'
                    : item.occupancyRate >= 50
                    ? 'RGBA(255, 159, 0)'
                    : 'RGBA(255, 79, 102)' 
                }
                backgroundColor2="rgba(245, 245, 245, 1)"
              />
              <small>{item.occupancyRate}%</small>
            </div>
          </div>
          <div className="d-flex align-items-center flexBottom asddd">
            <h6>{hostelName}</h6>
            <div className="total-projects ms-3"> 
              <div className='d-flex align-items-center'>
                <p className='marginR50'>Volno:</p>                                      
                <CountUp className={`project-counter count text- ${item.occupancyRate >= 90 ? 'greenCount' : item.occupancyRate >= 50 ? 'orangeCount' : 'redCount'}`} end={hostelBedInfo[item.hostelId].totalBeds - hostelBedInfo[item.hostelId].occupiedBeds} duration="5"/>                                            
                <p>lůžek</p>
              </div>
            </div>
            <div className="total-projects ms-3">  
              <div className='d-flex align-items-center'>
                <p className='marginR50'>Obsazeno:</p>                                       
                <CountUp className={`project-counter count text-`} end={hostelBedInfo[item.hostelId].occupiedBeds} duration="5"/>                                            
                <p>lůžek</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
 );
})
}

<div className="col-xl-4 width100">
              <div className="card box-hover cartFlexRight marginB10">
                  <div className="card-body cartBodyFlexRight paddingRemas">
                  <Link to={"/hostelsTab"} className='zobrazitvse' style={{marginTop:'0px'}}>zobrazit vše</Link>
                  </div>
              </div>
          </div>
  




                  
                  {/* <div>
                    <div className="d-inline-block position-relative donut-chart-sale mb-3">                     
                      <DonutChart  value="38" backgroundColor="rgba(255 79 102)" backgroundColor2="rgba(245, 245, 245, 1)" />
                      <small>38%</small>
                    </div>
                  </div>

                  <div>
                    <h4>456</h4>
                    <h5>Foods</h5>
                  </div> */}
                </div>
              </div>
            </div>


             
           
           
           {/* <Projects /> */}

            </div>
             {/* <div className="col-xl-9">
            <Projects />
            </div>  */}

              {/* <div className="col-xl-9 bst-seller"> */}
              <div className="col-xl-75 bst-seller">
                <EmployeesTableList 
                setEventsUpdateTrigger={setEventsUpdateTrigger}
                />
              </div>


						
            </div>

        

				<div className="row">
          
					<div className="col-xl-9 wid-100 fullWidth">
						<div className="row">
						

            
			
			
							
             
             
							<div className="col-xl-4">
								{/* <ToDoList /> */}
							</div>

						</div>
					</div>
					<div className="col-xl-3 t-earn">
						{/* <ProjectOverviewTab /> */}
					</div>
					<div className="col-xl-6 active-p">
						{/* <ActiveProjects /> 
					</div>
					<div className="col-xl-6 col-md-6 flag">
						{/* <ActiveUserMap />		 */}
					</div>
					<div className="col-xl-4 col-md-6 chat-map">
						{/* <ChatElementBlog /> */}
					</div>
					<div className="col-xl-5 bst-seller">
						{/* <BestSellerTable /> */}
					</div>
					<div className="col-xl-3 col-md-6 up-shd">
						{/* <UpcomingBlog /> */}
					</div>

					{/* <div className="col-xl-3 col-md-6 up-shd">
							 <ProjectStatusBlog  title="Projects Status"/> 
					</div> */}
					{/* <div className="col-xl-9 bst-seller">
						<EmployeesTableList />
					</div> */}
					<div className="col-xl-9 bst-seller">
					<HostelsTableList 
          setEventsUpdateTrigger={setEventsUpdateTrigger}
          />
					</div> 
                    <div className="col-xl-9 bst-seller">
					<TovarnyTableList 
          setEventsUpdateTrigger={setEventsUpdateTrigger}
          />
					</div> 
				</div>							
			</div>			
		</>
	)
}
export default Home;