// companyReducer.js
const initialState = {
    firstCompany: '',
  };
  
  const companyReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_FIRST_COMPANY':
        return {
          ...state,
          firstCompany: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default companyReducer;
  