import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import EventService from '../../../services/EventService';
import { useDispatch } from 'react-redux';
import { setHostelData } from '../../../store/actions/HostelActions';
import { setTovarnaData } from '../../../store/actions/TovarnaActions';

const EventsModal = forwardRef(({ events, LastEvents }, ref) => {
  const [show, setShow] = useState(false);
  const monthNames = ['Led', 'Ún', 'Bře', 'Dub', 'Kvě', 'Čvn', 'Čvc', 'Srp', 'Zář', 'Říj', 'Lis', 'Pro'];    
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    showModal() {
      setShow(true);
    }
  }));

  const closeModal = () => {
    setShow(false);
  };

  const handleDescriptionClick = (clickedEvent) => {
    let destination = '';
    switch (clickedEvent.type) {
      case EventService.Personal:
        destination = `/employee/${clickedEvent.employee.mobile}`;
        break;
      case EventService.Hostel:
        dispatch(setHostelData(clickedEvent.hostel));
        destination = '/profile-hostels';
        break;
      case EventService.Work:
        dispatch(setTovarnaData(clickedEvent.workplace));
        destination = '/profile-tovarna';
        break;
    }

    if (destination !== '') {
      setTimeout(() => {
        navigate(destination);
      }, 1000);
    }
  };

  useEffect(() => {

    console.log('EventsModal -- events', events);
  }, []);

  return (
    <Offcanvas
      show={show}
      onHide={setShow}
      className="offcanvas-end customeoff"
      placement='end'
    >
      <div className="offcanvas-header">
        <h5 className="modal-title" id="#gridSystemModal">Události</h5>
        <button type="button" className="btn-close"
          onClick={closeModal}
        >
          <i className="fa-solid fa-xmark"></i>
        </button>
      </div>
      <div className="offcanvas-body">
        <div className="container-fluid">
           {/* {
           events && events.map((event) => {
              return (
                <div>
                  {
                    event.employee && (
                      <Link to={`/employee/${event.employee.mobile}`}>
                        {`${event.employee.name} - `}
                      </Link>
                    )
                  }
                  <Link to='#' onClick={() => handleDescriptionClick(event)}>
                    {event.description}
                  </Link>
                </div>
              );
            })
          }  */}
          <div className="events">
  {events && events.length > 0 && events.map((LastEvent, index) => {
    const eventDate = new Date(LastEvent.date);
    console.log('eventDate', eventDate);
    console.log('eventDate.getMonth()', eventDate.getMonth());
    const monthAbbreviation = monthNames[eventDate.getMonth()];
    console.log('monthAbbreviation', monthAbbreviation);
    return (
      <div className="event-media" key={index}>
        <div className="d-flex align-items-center">
          <div className="event-box">
            <h5 className="mb-0">{eventDate.getDate()}</h5>
            <span>{monthAbbreviation}</span>
          </div>
          <div className="event-data ms-2">
            <h5 className="mb-0"><Link to={"#"}>{LastEvent.description}</Link></h5>
            {/* <span>w3it Technologies</span> */}
          </div>
        </div>
        <span className="text-secondary">{LastEvent.time}</span>
      </div>
    );
  })}
</div>

        </div>
      </div>
    </Offcanvas>
  );
});

export default EventsModal;