import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {Link } from 'react-router-dom'

const EmployeeEvents = (props) => {
  const firstCompany = useSelector(state => (state.company && state.company.firstCompany) || '');
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const { employee } = props;
    fetchEvents(employee.email);
  }, []);

  useEffect(() => {
    const { employee } = props;
    fetchEvents(employee.email);
  }, [firstCompany]);
 
  const fetchEvents = async (email) => { 
    console.log('EmployeeEvents -- email', email); 
    try {
      const response = await fetch(`/employee/events/get.php?email=${email}&firstCompany=${firstCompany}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });  

      // Проверяем успешность удаления данных на сервере
      if (!response.ok) {
        console.log('Не удалось удалить данные на сервере');
        throw new Error('Не удалось удалить данные на сервере');
      }

      const data = await response.json();
      if (data) {
        console.log('EmployeeEvents -- data ', data);
        console.log('EmployeeEvents -- data.events ', data.events);
        setEvents(data.events);
        return;
      }

      console.error(data.message);
    } catch (error) {
      console.error('Ошибка при загрузке данных:', error);
    }
  };

  const monthNames = ['Led', 'Ún', 'Bře', 'Dub', 'Kvě', 'Čvn', 'Čvc', 'Srp', 'Zář', 'Říj', 'Lis', 'Pro'];

  return (

    <div className="events">
       
                        {events.map((event, index) => {
                        const eventDate2 = new Date(event.datetime);
                        const monthAbbreviation2 = monthNames[eventDate2.getMonth()].slice(0, 3);
                              return (
                                <div className="event-media" key={index}>
                                    <div className="d-flex align-items-center">
                                        <div className="event-box">
                                            <h5 className="mb-0">{eventDate2.getDate()}</h5>
                                            <span>{monthAbbreviation2}</span>
                                        </div>
                                        <div className="event-data ms-2">
                                            <h5 className="mb-0"><Link to={"#"}>{event.description}</Link></h5>
                                            {/* <span>w3it Technologies</span> */}
                                        </div>
                                    </div>
                                    <span className="text-secondary">{event.time}</span>
                                </div>
                              );
                            })}                           
                        
                    </div>


    // <div className="container-fluid pt-4 border-bottom-1 pb-3">
    //   <h4 className="text-primary mb-4">
    //     Employee events
    //   </h4>
    //   <div id="events-tbl_wrapper" className="dataTables_wrapper no-footer table-responsive">
    //     <table
    //       id="events-tbl employeeTable"
    //       className="table no-footer mb-0"
    //     >
    //       <thead>
    //         <tr>
    //           <th>Date</th>
    //           <th>Time</th>
    //           <th>Description</th>
    //         </tr>
    //       </thead>
    //       <tbody>
    //         {
    //           events.map((event, index) => {
    //             return (
    //               <tr key={index}>
    //                 <td>{event.date}</td>
    //                 <td>{event.time}</td>
    //                 <td className={`fs-5 text-${event.color}`}>
    //                   <p className='d-block text-truncate'>
    //                     {event.description}
    //                   </p>
    //                 </td>
    //               </tr>
    //             );
    //           })
    //         }
    //       </tbody>
    //     </table>
    //   </div>
    // </div>
  );
};

export default EmployeeEvents;