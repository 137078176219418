import React, { useState, forwardRef, useImperativeHandle, useEffect  } from 'react';
import { useSelector } from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import { Offcanvas } from 'react-bootstrap';
import axios from 'axios';


const ZamestnatInEmployee = forwardRef((props, ref) => {
    const firstCompany = useSelector(state => (state.company && state.company.firstCompany) || '');
    const [hostelsData, setHostels] = useState([]);
    const [tovarnyData, setTovarny] = useState([]);
    const [selectedSelect, setSelectedSelect] = useState(null);
    const [selectedTovarna, setSelectedTovarna] = useState(null);
    const [selectedTovarnaId, setselectedTovarnaId] = useState(null);   
    const [Rooms, setRooms] = useState([]);
    const [RoomsCapacity, setRoomsCapacity] = useState([]);
    const [currentDate, setCurrentDate] = useState('');
    const {CheckBoxEmployees, clearAllCheckboxes, setEventsUpdateTrigger} = props;
    const [email, setEmail] = useState('');
    const [serverEmails, setServerEmails] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    
  

    useEffect(() => { 

    fetchDataTovarny();
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    setCurrentDate(formattedDate);
  }, []);


  useEffect(() => { 

    fetchDataTovarny();
  }, [firstCompany]);


  const fetchDataTovarny = async () => {
    try {
      const response = await fetch(`/${firstCompany}/tovarny.json?${Date.now()}`);
      const data = await response.json();
      setTovarny(data);
    } catch (error) {
      console.error('Ошибка при загрузке данных:', error);
    }
  };

    const { setDataUpdateTrigger } = props;
    const [ZamestnatE, setZamestnatE] = useState(false);

    


    const showZamestnatEModal = () => {
      setZamestnatE(true);
    };
  
    useImperativeHandle(ref, () => ({
      showZamestnatModal() {
        setZamestnatE(true);
      },
      showZamestnatEModal, // Передаем метод в ref
    }));
  
    const nav = useNavigate();
  
    const handleSubmit = async (e) => {
      e.preventDefault();

      console.log(CheckBoxEmployees);
      console.log(selectedTovarna);
      
      const dataToServer = {
        CheckBoxEmployees:CheckBoxEmployees,
        selectedTovarna:selectedTovarna,
        firstCompany:firstCompany,
        selectedTovarnaId:selectedTovarnaId
      };

      const url = `/ZamestnatInEmployee.php`; 
      
      console.log('dataToServer', dataToServer);
      
      try {
       
        const response = await axios.put(url, dataToServer);
        
        if (response.status === 200) {
          // Запрос выполнен успешно, обновляем компонент
          setDataUpdateTrigger((prev) => !prev);
          setEventsUpdateTrigger((prev) => !prev);
          console.log('Данные успешно обновлены');
        } else {
          // Обработка ошибок, если запрос вернул ошибку
          console.error('Ошибка при обновлении данных:', response.data.message);
        }
      } catch (error) {
        console.error('Ошибка при выполнении запроса:', error);
      }
      
      setZamestnatE(false);
      clearAllCheckboxes();
    };
      
    

      const handleTovarnaSelect = (event) => {
        const selectedHostelInfo = event.target.value.split(',');
          const selectedHostelId = selectedHostelInfo[0];
          const selectedHostelName = selectedHostelInfo[1];
        
        setselectedTovarnaId(selectedHostelId);
        
        setSelectedTovarna(selectedHostelName);

      };



    return (
        <>
            <Offcanvas show={ZamestnatE} onHide={setZamestnatE} className="offcanvas-end customeoff" placement='end'>
				<div className="offcanvas-header">
              
					<h5 className="modal-title" id="#gridSystemModal">Zaměstnat</h5>
					<button type="button" className="btn-close" 
						onClick={()=>setZamestnatE(false)}
					>
						<i className="fa-solid fa-xmark"></i>
					</button>
				</div>
				<div className="offcanvas-body">
                    <div className="container-fluid">
                        
                        <form onSubmit={handleSubmit}>
                            <div className="row">
           
                                <div className="col-xl-6 mb-3">
        <label className="form-label">Středisko</label>
        <select
          name="stredisko"
          className="default-select form-control"
          onChange={handleTovarnaSelect}
        >
          <option data-display="Select">-</option>
          {tovarnyData.map((tovarna, index) => (
            <option key={index} value={`${tovarna.id},${tovarna.name}`}>
              {tovarna.name}
            </option>
          ))}
        </select>
      </div>

                              
   

                            </div>
                            <div>
                                <button type="submit" className="btn btn-primary me-1">Zaměstnat</button>
                                <Link to={"#"} onClick={()=>setZamestnatE(false)} className="btn btn-danger light ms-1">Zrušit</Link>
                            </div>
                        </form>
                    </div>
				</div>
			</Offcanvas>     
        </>
    );
});

export default ZamestnatInEmployee;