import React, {useState, useRef, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Link, useParams} from 'react-router-dom';
import RoomsOffcanvas from '../../../constant/RoomHostelsOffcanvas'; 
import EmployeeToRoomOffcanvas from '../../../constant/EmployeeToRoomOffcanvas';
import CheckOutOffcanvas from '../../../constant/CheckOutOffcanvas';
import EditRoomsOffcanvas from '../../../constant/EditRoomsOffcanvas';
import { setRoomData } from '../../../../store/actions/RoomActions';
import { CSVLink } from 'react-csv';


const ProfileHostelTableList = () => {
  const firstCompany = useSelector(state => (state.company && state.company.firstCompany) || '');
    // const hostelData = useSelector((state) => state.hostel.hostelData);
    let { id } = useParams(); 
    const dispatch = useDispatch();

    const handleLinkClickRoom = (hostel) => {
    dispatch(setRoomData(hostel));
  };
          
    const [dataUpdateTrigger, setDataUpdateTrigger] = useState(false);
    const [selectedRoom, setselectedRoom] = useState(null);
    const [selectedHostel, setselectedHostel] = useState(null);
    const room = useRef();   
    const editRoom = useRef();
    const addEmpToRoom = useRef();
    const checkOut = useRef();
    const [Rooms, setRooms] = useState([]);
    const [RoomsAll, setRoomsAll] = useState([]);
    const [RoomsCapacity, setRoomsCapacity] = useState([]);
    const [hostels, setHostels] = useState([]);
    const [hostelData, setHostelData] = useState([]);

  useEffect(() => {
    fetchData77();
    fetchData();
    fetchDataPokoje();
  }, [firstCompany, dataUpdateTrigger]);

  const fetchData = async () => {
    try {
      const response = await fetch(`/${firstCompany}/pokoje.json?${Date.now()}`);
      const data = await response.json();
      console.log('data-now',data);
      const thisRooms = data.filter(room => room.hostelId === id);
      console.log('thisRooms-now',thisRooms);

      const sortedRooms = thisRooms.slice().sort((roomA, roomB) => {
  // Функция для разбора id
  const parseId = (id) => {
    const match = id.match(/(\d+)([A-Za-z]*)/);
    if (match) {
      return {
        num: parseInt(match[1], 10),
        str: match[2],
      };
    } else {
      // Если формат id не соответствует ожиданиям, вернуть значения по умолчанию
      return {
        num: 0,
        str: '',
      };
    }
  };

  const parsedA = parseId(roomA.id);
  const parsedB = parseId(roomB.id);

  // Сравнение числовой части
  if (parsedA.num < parsedB.num) {
    return -1;
  } else if (parsedA.num > parsedB.num) {
    return 1;
  } else {
    // Если числовые части равны, сравнение строковой части
    return parsedA.str.localeCompare(parsedB.str);
  }
});

console.log('sortedRooms-now', sortedRooms);

      setRooms(sortedRooms);
    } catch (error) {
      console.error('Ошибка при загрузке данных:', error);
    }
  };

  const fetchDataPokoje = async () => {
    try {
      const response = await fetch(`/${firstCompany}/file.json?${Date.now()}`);
      const data = await response.json();
  
      const roomCount = {}; 

      data.forEach((client) => {
        if (client.hostelId === id) {
          const key = `${client.ubytovna} *-* ${client.roomID}`;
          if (!roomCount[key]) {
            roomCount[key] = 1;
          } else {
            roomCount[key]++;
          }
        }
      }); 

      console.log('1-roomCount', roomCount);
  
      const roomCountArray = Object.keys(roomCount).map((key) => {
        const [ubytovna, roomID] = key.split(' *-* ');
        return { ubytovna, roomID, count: roomCount[key] };
      });

      console.log('1-roomCountArray', roomCountArray);

       setRoomsCapacity(roomCountArray);
  
    } catch (error) {
      console.error('Ошибка при загрузке данных:', error);
    }
  };
  
  const fetchData77 = async () => {
    try {
      const response = await fetch(`/${firstCompany}/ubytovny.json?${Date.now()}`);
      const data = await response.json();
      const foundHostel = data.find(item => item.id === id);
      setHostelData(foundHostel);
      setHostels(data);
    } catch (error) {
      console.error('Ошибка при загрузке данных:', error);
    }
  };
 
  


  const handleDeletePokoj = async (ubytovna, pokoj) => {
    try {
      // Отправляем запрос на сервер для удаления комнаты по имени и номеру
      const response = await fetch('/delete_pokoj.php', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ubytovna, pokoj, firstCompany }),
      });
  
      // Проверяем успешность удаления данных на сервере
      if (!response.ok) {
        console.log('Не удалось удалить данные на сервере');
        throw new Error('Не удалось удалить данные на сервере');
      }
  
      // Обновляем состояние Rooms, убирая удаленную комнату
      setRooms(prevRooms => prevRooms.filter(room => room.ubytovna !== ubytovna || room.id !== pokoj));
    } catch (error) {
      console.error('Ошибка при удалении комнаты:', error);
    }
  };
  
  
  
  const handleEdit = (roomId, hostelId) => {
    
    setselectedRoom(roomId); 
    setselectedHostel(hostelId);
    editRoom.current.showeditRoomModal();
  };

  const handleCheckIn = (room) => {
    
    setselectedRoom(room); // Устанавливаем выбранного работника для редактирования
    addEmpToRoom.current.showaddEmpToRoomModal();
  };

  const handleCheckOut = (room) => {
   
    setselectedRoom(room); // Устанавливаем выбранного работника для редактирования
    checkOut.current.showcheckOutModal();
                    
  };

  function createDeletingPopUpPokoj(ubytovna, id) {
    
    var overlay = document.createElement("div");
    overlay.className = "overlay";
  
    
    var popup = document.createElement("div");
    popup.className = "popup"; 

    var buttonspopup = document.createElement("div");
    buttonspopup.className = "buttonspopup";
  
    
    var text = document.createElement("p");
    text.textContent = "Opravdu chcete pokoj trvale smazat ?";

    var logoPoUp = document.createElement("img");
    logoPoUp.src = '/static/media/Logo%20OWB%20website.f8178ceeb0449f70be9f.png';
    logoPoUp.classList.add('logoPoUp');
    
    var yesButton = document.createElement("button");  
    yesButton.classList.add('btn', 'btn-primary');
    yesButton.textContent = "Ano";
    yesButton.addEventListener("click", function () {
      //  удаление
      handleDeletePokoj(ubytovna, id);
      closePopUp(overlay, popup);
    });
  
    
    var cancelButton = document.createElement("button"); 
    cancelButton.classList.add('btn', 'btn-danger', 'shadow');
    cancelButton.textContent = "Zrušit";
    cancelButton.addEventListener("click", function () {
      closePopUp(overlay, popup);
    });
  
    
    popup.appendChild(logoPoUp);
    popup.appendChild(text);
    buttonspopup.appendChild(yesButton);
    buttonspopup.appendChild(cancelButton);
    popup.appendChild(buttonspopup);
    document.body.appendChild(overlay);
    document.body.appendChild(popup);

    setTimeout(() => {
      
    },20000);

    // const timerPopUp = setTimeout(function() {
    //   overlay.remove();
    //   popup.remove();
    // }, 20000);

  }
  
  function closePopUp(overlay, popup) {
    overlay.remove();
    popup.remove();
    // clearTimeout(timerPopUp);
  }

  useEffect(() => {
    fetchData77();
    fetchData();
    fetchDataPokoje();
    console.log('1. dataUpdateTrigger', dataUpdateTrigger);
  }, [dataUpdateTrigger]);
  
  

  // excel export:

const csvlink = {
  data : Rooms,
  filename: "Pokoje - Jobsi export.csv"
}


// END excel export


const fetchDataSetEmp = async () => {
  try {
    const response = await fetch(`/${firstCompany}/file.json?${Date.now()}`);
    const data = await response.json();
 
    setClients(data);


  } catch (error) {
    console.error('Ошибка при загрузке данных:', error);
  }
};

const [clients, setClients] = useState([]);
const [hostelBedInfo, setHostelBedInfo] = useState({});

useEffect(() => {
  fetchDataSetEmp();
  // fetchDataRooms();
}, [firstCompany]); 

// const fetchDataRooms = async () => {
//   try {
//     const response = await fetch(`/${firstCompany}/pokoje.json?${Date.now()}`);
//     const data = await response.json();
//     setRoomsAll(data);
//   } catch (error) {
//     console.error('Ошибка при загрузке данных:', error);
//   }
// };



useEffect(() => {
const updatedHostelBedInfo = {};
Rooms.forEach((room) => {
const { hostelId, quantity } = room;
if (!updatedHostelBedInfo[hostelId]) {
updatedHostelBedInfo[hostelId] = {
  totalBeds: 0,
  occupiedBeds: 0,
};
}
updatedHostelBedInfo[hostelId].totalBeds += parseInt(quantity, 10);
});

clients.forEach((client) => {
const { hostelId, room } = client;
if (updatedHostelBedInfo[hostelId] && room !== '-' && room !== null) {
updatedHostelBedInfo[hostelId].occupiedBeds += 1;
}
});

console.log('updatedHostelBedInfo', updatedHostelBedInfo);
setHostelBedInfo(updatedHostelBedInfo);
}, [Rooms, clients, hostelData]);
  
    return (
        <>
            <div className="card profilBoxSh">            
                <div className="card-body p-0">
                    <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">   
                        <div className="tbl-caption">
                          <div style={{ display: 'flex' }}>
                            <h4 className="heading mb-0" style={{ marginRight: '20px' }}>{hostelData.name}</h4>
                            
                            <p className="text-primary" style={{ marginRight: '100px' }}>{hostelData.adress}</p>
                      <div style={{ display: 'flex', minWidth: '230px' }}>
                      {hostelBedInfo && hostelBedInfo[hostelData.id] && (
                        <><p className="text-primary"  style={{ marginRight: '10px' }}>Obsazenost:</p><div className="beds-container" style={{ width: '100%' }}>
                        <div className="progress">
                          <div className="progress-bar"
                            role="progressbar"
                            style={{
                              width: `${(((hostelBedInfo[hostelData.id].occupiedBeds) / (hostelBedInfo[hostelData.id].totalBeds)) * 100).toFixed(0)}%`, backgroundColor: (((hostelBedInfo[hostelData.id].occupiedBeds) / (hostelBedInfo[hostelData.id].totalBeds)) * 100).toFixed(0) >= 90
                                ? 'RGBA(58, 201, 119)'
                                : (((hostelBedInfo[hostelData.id].occupiedBeds) / (hostelBedInfo[hostelData.id].totalBeds)) * 100).toFixed(0) >= 50
                                  ? 'RGBA(255, 159, 0)'
                                  : 'RGBA(255, 79, 102)'
                            }}
                            aria-valuenow={(((hostelBedInfo[hostelData.id].occupiedBeds) / (hostelBedInfo[hostelData.id].totalBeds)) * 100).toFixed(0)}
                            aria-valuemin="0"
                            aria-valuemax="100">
                            {(((hostelBedInfo[hostelData.id].occupiedBeds) / (hostelBedInfo[hostelData.id].totalBeds)) * 100).toFixed(0)}%
                          </div>
                        </div>
                      </div></>
                      )}
      </div>
                            </div>
                            
                            <div className='displayF'>
                            <div>
                                            <CSVLink {...csvlink} className="btn btn-primary light btn-sm csvLink">
                                                <i className="fa-solid fa-file-excel" /> {" "} 
                                                Exportovat do Excelu
                                            </CSVLink>  
                                        </div> 
                                <Link to={"#"} className="btn btn-primary btn-sm" data-bs-toggle="offcanvas"
                                   onClick={()=>room.current.showEmployeModal()}
                                >+ Přidat pokoj</Link> {" "}
                                
                            </div>
                        
                        </div>  
                        <div style={{ display: 'flex', paddingLeft: '20px' }}>
                            <p className="text-primary" style={{  marginRight: '20px' }}><span className="black-color">Tel.:</span> {hostelData.mobile}</p>
                            <p className="text-primary" ><span className="black-color">Email: </span> {hostelData.email}</p>
                            </div>        
                        <div id="employee-tbl_wrapper" className="dataTables_wrapper no-footer">
                            <table id="projects-tbl" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                <thead>
                                    <tr>
                                        {/* <th className="sorting_asc_11" >
                                            <div className="form-check custom-checkbox ms-0">
                                                <input type="checkbox" className="form-check-input checkAllInput" required="" 
                                                     onClick={()=>handleCheckedAll(unchecked)}
                                                />
                                                <label className="form-check-label" htmlFor="checkAll"></label>
                                            </div>
                                        </th> */}
                                        {/* <th>Employee ID</th> */}
                                        <th>Číslo pokoje</th>
                                        <th></th>
                                        <th>Počet lůžek</th>
                                        <th>Volno</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                  {console.log('Rooms-now',Rooms)}
                                {Rooms.slice().reverse().map((room, index) => (
                                        <tr key={index}>
                                            
                                            <td>
                                                <div className="products">
                                                    
                                                    <div>
                                                        <h6><Link to={`/profile-room/${room.hostelId}/${room.id}`} onClick={() => handleLinkClickRoom(room)}>{room.name}</Link></h6>
                                                        
                                                    </div>	
                                                </div>
                                            </td>
                                            
                                            <td>
                                              
                                            {/* <Link
  to="#"
  className={`btn btn-primary btn-xxs shadow ${Number(room.quantity) - Number(RoomsCapacity[room.id]) <= 0 ? "gray-bg" : ""}`}
  
  onClick={() => {
    const howQuantity = Number(room.quantity) - Number(RoomsCapacity[room.id]);

    if (howQuantity <= 0) {
      return;
    } else {
      handleCheckIn(room);
    }
  }}
>
  Check-In
</Link> */}

<Link
  to="#"
  className={`btn btn-primary btn-xxs shadow ${Number(room.quantity) - Number(RoomsCapacity.find((data) => data.roomID === room.id)?.count || 0) <= 0 ? "gray-bg" : ""}`}
  onClick={() => {
    const roomData = RoomsCapacity.find((data) => data.roomID === room.id);
    const availableCount = roomData ? Number(room.quantity) - roomData.count : Number(room.quantity);

    if (availableCount <= 0) {
      return;
    } else {
      handleCheckIn(room);
    }
  }}
>
  Check-In
</Link>


                          <Link
                            to={"#"}
                            className="btn btn-outline-danger btn-xxs ms-1"
                            onClick={() => {
                            // Предотвращаем переход по ссылке
                              handleCheckOut(room);
                            }} >
                            Check-Out
                          </Link>
                          </td>       
                                            <td><Link to={"#"} className="text-primary">{room.quantity}</Link></td>
                                            <td>
  <Link to="#" className="text-primary">
    {(() => {
      const roomData = RoomsCapacity.find((data) => data.roomID === room.id);
    
      if (roomData) {
        const availableCount = Number(room.quantity) - roomData.count;
        return availableCount >= 0 ? availableCount : 0;
      } else {
        return Number(room.quantity);
      }
    })()}
  </Link>
</td>

                                            <td>
                          <div className="d-flex" style={{justifyContent: 'flex-end'}} >  
                          <Link
                              to={"#"}
                              className="btn btn-primary shadow btn-xs sharp me-1" data-bs-toggle="offcanvas" onClick={() => handleEdit(room.id, room.hostelId)}
                            >
                              <i className="fas fa-pencil-alt"></i>
                            </Link>  
                            <Link
              to="#"
              className={`btn btn-danger shadow btn-xs sharp ${
                (() => {
                  const roomData = RoomsCapacity.find((data) => data.roomID === room.id);
                  if (roomData) {
                    console.log('roomData.count', roomData.count);
                    console.log('roomData', roomData)
                    return  "pointerNone";
                  } else {
                    return "else0";
                  }
                })()
              }`}
              onClick={() => createDeletingPopUpPokoj(room.ubytovna, room.id)}
            >

                              <i className="fa fa-trash"></i>
                            </Link>
                          </div>
                        </td>
                                        </tr>
                                    ))}
                                </tbody>                                
                            </table>
                            <div className="d-sm-flex text-center justify-content-between align-items-center">
                                <div className='dataTables_info'>
                                    {/* Showing {lastIndex-recordsPage + 1} to{" "}
                                    {tableData.length < lastIndex ? tableData.length : lastIndex}
                                    {" "}of {tableData.length} entries */}
                                </div>
                                {/* <div
                                    className="dataTables_paginate paging_simple_numbers justify-content-center"
                                    id="example2_paginate"
                                >
                                    <Link
                                        className="paginate_button previous disabled"
                                        to="#"                                        
                                        onClick={prePage}
                                    >
                                        <i className="fa-solid fa-angle-left" />
                                    </Link>
                                    <span>                                      
                                        {number.map((n , i )=>(
                                            <Link className={`paginate_button ${currentPage === n ? 'current' :  '' } `} key={i}                                            
                                                onClick={()=>changeCPage(n)}
                                            > 
                                                {n}  
                                            </Link>
                                        ))}
                                    </span>
                                    <Link
                                        className="paginate_button next"
                                        to="#"                                        
                                        onClick={nextPage}
                                    >
                                        <i className="fa-solid fa-angle-right" />
                                    </Link>
                                </div> */}
                            </div> 
                        </div>
                    </div>
                </div>
            </div>           
            <RoomsOffcanvas 
                ref={room}
                Title="Add Hostel"
                setDataUpdateTrigger={setDataUpdateTrigger}
                hostelData={hostelData}
            />
            <EditRoomsOffcanvas 
                ref={editRoom}
                Title="Edit Room"
                setDataUpdateTrigger={setDataUpdateTrigger}
                selectedRoom={selectedRoom}
                selectedHostel={selectedHostel}
                hostelData={hostelData}
            /> 
            <EmployeeToRoomOffcanvas 
                ref={addEmpToRoom}
                Title="checkIn"
                selectedRoom={selectedRoom}
                setDataUpdateTrigger={setDataUpdateTrigger}
                dataUpdateTrigger={dataUpdateTrigger}
                hostelData={hostelData}
            /> 
             <CheckOutOffcanvas 
                ref={checkOut}
                Title="CheckOut"
                selectedRoom={selectedRoom}
                setDataUpdateTrigger={setDataUpdateTrigger}
            /> 
             
            {/* <InviteCustomer
                ref={invite}     
                Title="Invite Employee"
            /> */}
        
        </>
    );
};


export default ProfileHostelTableList;