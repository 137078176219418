import React from 'react';
import { setFirstCompany } from './../../store/actions/companyActions';
import {
    formatError,
    login,
    runLogoutTimer,
    saveTokenInLocalStorage,
    signUp,
} from '../../services/AuthService';

export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';
export const NAVTOGGLE = 'NAVTOGGLE';

export function Logout(navigate) {
    localStorage.removeItem('bearerToken');
    navigate('/login');

    return {
        type: LOGOUT_ACTION,
    };
}

// FOR LARAVEL -> POSTGREsql:

// export function loginAction(userLogin, password, navigate) {
    
//     return (dispatch) => {
//         login(userLogin, password)
//             .then((response) => {
//                 saveTokenInLocalStorage(response.token);

//                 dispatch(loginConfirmedAction(response));
//                 navigate('/dashboard');
//             })
//             .catch((error) => {
//                 dispatch(loginFailedAction(error.response.data.message));
//             });
//     };
// }

// FOR PHP -> JSON:

export function loginAction(userLogin, password, navigate) {
    if (userLogin == 'info@originalworkbroker.cz' && password == 'evidence_PRACE2024' 
    || userLogin == 'info@originalworkbroker.cz' && password == 'Сoordinator*' 
    || userLogin == 'aspector' && password == 'aspector*' 
    || userLogin == 'workczlm@gmail.com' && password == 'AGowbstaff_24green' 
    || userLogin == 'patrik.bortel@seznam.cz' && password == 'AGowbstaff_24blue' 
    || userLogin == 'Dimailchuk@gmail.com' && password == 'AGowbstaff_24red' 
    || userLogin == 'ganna.g@seznam.cz' && password == 'AGowbstaff_24yellow' 
    || userLogin == 'svetiromanova@icloud.com' && password == 'AGowbstaff_24black' 
    || userLogin == 'danjukova@nansplus.cz' && password == 'AGowbstaff_24orange' 
    || userLogin == 'keti78@seznam.cz' && password == 'AGowbstaff_24purple' 
    || userLogin == 'martin.slajer@seznam.cz' && password == 'AGowbstaff_24gray' 
    || userLogin == 'vladik.op@seznam.cz' && password == 'AGowbstaff_24pink'
    || userLogin == 'jmojs44@gmail.com' && password == 'AGowbstaff_24brown') {

        userLogin = 'info@originalworkbroker.cz';
        password = 'evidence_PRACE2024';

    } else {
        userLogin = '123';
        password = '123';
        alert('Chybně zadané heslo nebo uživatelské jméno');
        return;
    }
    return async (dispatch) => {
        try {
            // Загрузка данных из JSON файла
            const response = await fetch(`https://online.jobsi.app/registered.json?${Date.now()}`);
            if (!response.ok) {
                throw new Error('Error loading user data file');
            }

            const userData = await response.json();

            // Поиск пользователя с совпадающими логином и паролем
            const foundUser = userData.find(user => user.email === userLogin && user.pw === password);

            if (foundUser) {
                // Если пользователь найден, выполняем аутентификацию
                login(userLogin, password)               
                    .then((response) => {
                        console.log('response', response);
                        saveTokenInLocalStorage(response.token);
                        setCookie("user_id", userLogin, 1); 
                        dispatch(loginConfirmedAction(response));
                        navigate('/dashboard');
                    })
                    .catch((error) => {
                        dispatch(loginFailedAction(error.response.data.message));
                    });
            } else {
                // Если пользователь не найден, обрабатываем ошибку
                alert('Invalid login or password');
                dispatch(loginFailedAction('Invalid login or password'));
            }
        } catch (error) {
            // Обработка ошибки при загрузке файла
            dispatch(loginFailedAction(error.message));
        }
    };
}

function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + value + expires + "; path=/";
}

export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function signupAction(data, navigate) {
    console.log('data -- AuthActions.js', data.company_name);
    console.log('data -- AuthActions.js', data);
    const loginData = data;
    console.log('loginData -- AuthActions.js', loginData);
    if (loginData.company_name === '' ) {
        loginData.company_name = 'Moje první agentura';
      }
      console.log('loginData 2 -- AuthActions.js', loginData);
    return (dispatch) => {
        signUp(loginData)
            .then((response) => {
                saveTokenInLocalStorage(response.token);
                setCookie("user_id", loginData.email, 1); 
                dispatch(setFirstCompany(loginData.company_name));
                dispatch(loginConfirmedAction(response));
                navigate('/dashboard'); 
            })
            .catch((error) => {
                dispatch(signupFailedAction(error.response.data.message));
            });
    };
};

export function signupFailedAction(data) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: data,
    };
}
export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

// export function confirmedSignupAction(payload) {
//     return {
//         type: SIGNUP_CONFIRMED_ACTION,
//         payload: {
//             // Здесь могут быть моковые данные, которые были бы получены с сервера при успешной регистрации.
//             // Например, информация о зарегистрированном пользователе.
//             userId: 123,
//             username: 'alex',
//             // Другие данные...
//         },
//     };
// }



export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}

export const navtoggle = () => {
    return {
        type: 'NAVTOGGLE',
    };
};