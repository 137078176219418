import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import {
	loadingToggleAction, loginAction,
} from '../../store/actions/AuthActions';

import logo from '../../images/logo/logo-full.png';
import LogoWhite from '../../images/logo/jobsi logo.jpg';
import bg6 from '../../images/background/bg6.jpg';
import vstup from '../../images/background/vstup.png';
import VideoJOBSI from '../../images/background/VideoJOBSI.png';

const defaultErrors = {
	userLogin: '',
	password: '',
};

function Login(props) {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [heartActive, setHeartActive] = useState(true);

	const [userLogin, setUserLogin] = useState('');
	const [password, setPassword] = useState('');

	const [errors, setErrors] = useState(defaultErrors);

	function onLogin(e) {
		e.preventDefault();

		let error = false;
		const errorObj = defaultErrors;

		if (userLogin === '') {
			errorObj.userLogin = 'Email is Required';
			error = true;
		}

		if (password === '') {
			errorObj.password = 'Password is Required';
			error = true;
		}

		setErrors(errorObj);

		if (error) {
			return;
		}
		console.log('Login data:', { userLogin, password });
		
		dispatch(loadingToggleAction(true));
		dispatch(loginAction(userLogin, password, navigate));
	}

	return (
		<div className="page-wraper">
			<div className="browse-job login-style3">
				<div className="bg-img-fix overflow-hidden" style={{ background: '#fff url(' + bg6 + ')', height: "100vh" }}>
					<div className="row gx-0">
						<div className="col-xl-4 col-lg-5 col-md-6 col-sm-12 vh-100 bg-white reg-width">
							<div id="mCSB_1" className="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside" style={{ maxHeight: "653px" }}>
								<div id="mCSB_1_container" className="mCSB_container" style={{ position: "relative", top: "0", left: "0", dir: "ltr" }}>
									<div className="login-form style-2">
										<div className="card-body card-body22">
											<div className="logo-header logo-reg logo-header22">
												<Link to={"#"} className="logo logo22 marginR40"><img src={logo} alt="" className="width-230 light-logo" /></Link>
												<h1 className="regH1">INFORMAČNÍ SYSTÉM <span className="colorPink">JOBSI<br></br>
                              					PŘÍHLAŠENÍ K</span> ÚČTU</h1> 
											</div>
											<div className="nav nav-tabs border-bottom-0" >
												<div className="tab-content w-100" id="nav-tabContent">
													<div className="tab-pane fade active show" id="nav-personal">
														{props.errorMessage && (
															<div className='bg-red-300 text-red-900 border border-red-900 p-1 my-2'>
																{props.errorMessage}
															</div>
														)}
														{props.successMessage && (
															<div className='bg-green-300 text-green-900 border border-green-900 p-1 my-2'>
																{props.successMessage}
															</div>
														)}
														<form className=" dz-form pb-3 loginF" onSubmit={onLogin}>
															{/* <h3 className="form-title m-t0">Vítejte v informačním systému Jobsi!</h3>
															<div className="dz-separator-outer m-b5">
																<div className="dz-separator bg-primary style-liner"></div>
															</div> */}
															{/* <p>Prosim, zadejte své přihlašovací údaje pro přístup do systému.</p> */}
															<div className='twoInputs displayF sbetween'>
																<div className='twoInputsdiv'>
															<div className="form-group mb-3">
																<input
																	type="text"
																	className="form-control"
																	value={userLogin}
																	onChange={(e) => setUserLogin(e.target.value)}
																	placeholder="Vaš e-mail *"
																/>
																{errors.userLogin && (
																	<div className="text-danger fs-12">{errors.userLogin}</div>
																)}
															</div>
															<div className="form-group mb-3 mb0px">
																<input
																	type="password"
																	className="form-control"
																	value={password}
																	onChange={(e) => setPassword(e.target.value)}
																	placeholder="Vaše heslo *"
																/>
																{errors.password && (
																	<div className="text-danger fs-12">{errors.password}</div>
																)}
															</div> 
															</div>
															<button type="submit" className="btn btn-primary dz-xs-flex m-r5 loginB"><img className='width50And' src={vstup}></img><span>VSTOUPIT</span></button> 
															</div>
															{/* <div className="form-group text-left mb-5">
																 <button type="submit" className="btn btn-primary dz-xs-flex m-r5">Login</button> 
																<span className="form-check d-inline-block ms-2">
																	<input type="checkbox" className="form-check-input" id="remember_check" name="example1" />
																	<label className="form-check-label" htmlFor="remember_check">Pamatovat si mě</label>
																</span>
															</div> */}
														</form>
														<div className="text-center bottom">
															<NavLink to="/register" className="colorB" >
																Nemáte účet ? <span className='colorPink'>Registrovat se zdarma</span>
															</NavLink>
															<p className="colorB" >Nemůžete se příhlasít ? <span className="colorB reset">Resetovat heslo?</span></p>
														</div>
													</div>
												</div>
											</div>
											<p className="bottomText">Máte nějaký problém s  registraci nebo příhlašením ? 
Obrat´te se na naší technickou podporu: <span className="colorPink">support@jobsi.app</span></p>
										</div>
										
										{/* <div className="card-footer">
											<div className=" bottom-footer clearfix m-t10 m-b20 row text-center">
												<div className="col-lg-12 text-center">
													<span>Vytvořil tým<span
														className={`heart ${heartActive ? "" : "heart-blast"}`}
														onClick={() => setHeartActive(!heartActive)}
													></span>
														<a href="https://www.aspector.cz/" target="_blank">Aspector Digital</a></span>
												</div>
											</div>
										</div> */}
									</div>
								</div>
							</div>

							<div id="mCSB_1 mCSB_122" className="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside" style={{ maxHeight: "653px", marginTop: '50px' }}>
								<div id="mCSB_1_container" className="mCSB_container" style={{ position: "relative", top: "0", left: "0", dir: "ltr" }}>
									<div className="login-form style-2">
										<div className="card-body loginBC">
											<div className='displayFAlign'>
												<img className='screenPlayer' src={VideoJOBSI}></img>
												<div className='textPlayer'>
													<p className='bBack fontSize24'>PROHLEDNĚTE NAŠE VIDEONÁVODY A ZAČNĚTE ZRYCHLOVAT PROCESY UŽ ZA CHVÍLI</p>
													<p className='bBack asdddd'>Nemusíte ztracet čas organizaci školení pro váš personal a management.
														Navštívte naší <span className='bBack reset colorPink'>znalostní bazi</span> a budete mít vysvětleno během pouhých
														par minut.
													</p>
												</div>
											</div>
										</div>
										
										{/* <div className="card-footer">
											<div className=" bottom-footer clearfix m-t10 m-b20 row text-center">
												<div className="col-lg-12 text-center">
													<span>Vytvořil tým<span
														className={`heart ${heartActive ? "" : "heart-blast"}`}
														onClick={() => setHeartActive(!heartActive)}
													></span>
														<a href="https://www.aspector.cz/" target="_blank">Aspector Digital</a></span>
												</div>
											</div>
										</div> */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		errorMessage: state.auth.errorMessage,
		successMessage: state.auth.successMessage,
		showLoading: state.auth.showLoading,
	};
};
export default connect(mapStateToProps)(Login);