import React, { useRef } from "react";
import { Link } from 'react-router-dom';
import IssueAnAdvance from '../../constant/IssueAnAdvance';

const ProfileHeader = (props) => {
  const { employee } = props;
  const advanceModal = useRef();

  const openAdvanceModal = () => {
    advanceModal.current.showModal();
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="profile card card-body px-3 pt-3 pb-0" style={{background: '#fe8798', height: '100%', padding: '0px !important', justifyContent: 'center'}}>
            <div className="profile-head">
              <div className="profile-info" style={{ padding: '0px !important', alignItems: 'center', marginRight: '25px'}}>
                <div className="profile-details" style={{height: '170px', justifyContent: 'space-around', alignItems: 'center', background: '#ffffffbd', borderRadius: '10px', marginLeft: '1.5%'}}>
                {employee.gender === 'Mužský' ? (
                                    <img className="genderIconP" src="/male.png" />
                                  ) : employee.gender === 'Ženský' ? (
                                    <img className="genderIconP" src="/female.png" />
                                  ) : employee.gender === 'Prosím vyberte' ? (
                                    <img className="genderIconP" src="/malefemale.png" />
                                  ) : null}
                 <div>
                 <span  style={{color: 'black !important', fontSize:'23px'}}>Jméno: <span className="text-primary mb-0" style={{fontWeight: '600'}}>{employee.name}</span></span>
                  <div className="mobile1P"  style={{display: 'flex'}}>
                  <div className="profile-name" style={{marginRight:'10px'}}>
                    <p><span style={{marginRight: '7px'}}>Datum nárození:</span>{employee.birth}</p>
                    <p><span style={{marginRight: '7px'}}>Datum registrace:</span>{employee.registr}</p>
                    <p><span style={{marginRight: '7px'}}>Národnost:</span>
                    {employee.country === "CZ" ? (
                          <img className="iconWidth" style={{marginRight: '7px', borderRadius: '15px'}} src="/cz.jpg" />
                        ) : employee.country === "UA" ? (
                          <img className="iconWidth" style={{marginRight: '7px', borderRadius: '15px'}} src="/ua.jpg" />
                        ) : employee.country === "PL" ? (
                          <img className="iconWidth" style={{marginRight: '7px', borderRadius: '15px'}} src="/pl.webp" />
                        ) : employee.country === "LT" ? (
                          <img className="iconWidth" style={{marginRight: '7px', borderRadius: '15px'}} src="/lt.jpg" />
                        ) : employee.country === "RO" ? (
                          <img className="iconWidth" style={{marginRight: '7px', borderRadius: '15px'}} src="/ro.jpg" />
                        ) : employee.country === "MD" ? (
                          <img className="iconWidth" style={{marginRight: '7px', borderRadius: '15px'}} src="/md.jpg" />
                        ) : employee.country === "SVK" ? (
                          <img className="iconWidth" style={{marginRight: '7px', borderRadius: '15px'}} src="/sk.jpg" />
                        ) :  employee.country === "HU" ? (
                          <img className="iconWidth" style={{marginRight: '7px', borderRadius: '15px'}} src="/hu.jpg" />
                        ) : employee.country === "AZR" ? (
                          <img className="iconWidth" style={{marginRight: '7px', borderRadius: '15px'}} src="/az.jpg" />
                        ) : employee.country === "BGR" ? (
                          <img className="iconWidth" style={{marginRight: '7px', borderRadius: '15px'}} src="/bgr.jpg" />
                        ) : employee.country === "LV" ? (
                          <img className="iconWidth" style={{marginRight: '7px', borderRadius: '15px'}} src="/lv.jpg" />
                        ) : null
                        }
                      {employee.country}</p>
                  </div>
                  <div className="profile-email">
                  <p><span style={{marginRight: '7px'}}>Telefon:</span>{employee.mobile}</p>
                  <p><span style={{marginRight: '7px'}}>Mail:</span>{employee.email}</p>
                  </div>
                  </div>
                  </div>
                </div>
                <img className='empProfile' src="/empProfile.webp"></img>
                {/* <div className="div-addButton">
                  <Link to={"#"}
                    onClick={openAdvanceModal}
                    className="btn btn-primary btn-sm mx-1"
                  >
                    Issue an advance
                  </Link>
                  <Link className="btn btn-secondary btn-sm mx-1">
                    Issue wages
                  </Link>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <IssueAnAdvance
        ref={advanceModal}
        employee={employee}
        Title="Issue am advance"
      />
    </>
  );
};

export default ProfileHeader;