import React,{useState} from 'react'; 
import ProjectStatusBlogDonutChart from './ProjectStatusBlogDonutChart';
import { Dropdown } from 'react-bootstrap';
import { Link } from "react-router-dom";

const ProjectStatusBlog = ({title, strediskaGraf, strediskoCounts, employesAll, employesWork}) => {
    const [globalSelect, setGlobalSelect] = useState('Dnes');
    console.log('strediskaGraf', strediskaGraf);
    console.log('strediskaGraf', strediskoCounts);

    const keys = Object.keys(strediskoCounts).filter(key => key !== "-");

    // Сортируем ключи на основе значений
    keys.sort((a, b) => strediskoCounts[b] - strediskoCounts[a]);

    // Создаем новый объект с четырьмя самыми большими значениями
    const topFour = {};
    for (let i = 0; i < 4; i++) {
      const key = keys[i];
      topFour[key] = strediskoCounts[key];
    }
    console.log('topFour', topFour);
    const barGraf = [
        { title: Object.keys(topFour)[0], color: "var(--primary)", pracovniky: topFour[Object.keys(topFour)[0]] },
        { title: Object.keys(topFour)[1], color: "#3AC977", pracovniky: topFour[Object.keys(topFour)[1]] },
        { title: Object.keys(topFour)[2], color: "#FF9F00", pracovniky: topFour[Object.keys(topFour)[2]] },
        { title: Object.keys(topFour)[3], color: "#FF5E5E", pracovniky: topFour[Object.keys(topFour)[3]] }
      ];
      
      let totalPracovniky = 0;
      barGraf.map((data) => {
        // Добавляем значение data.pracovniky к общему количеству рабочих
        totalPracovniky += data.pracovniky;
         });

         console.log('employesWork', employesWork);
         console.log('totalPracovniky', totalPracovniky);

        let ostatniWorkers = employesWork - totalPracovniky;

    return (
        <>
            <div className="card">
                <div className="card-header pb-0 border-0">
                    <h4 className="heading mb-0">{title}</h4>                    
                    <Dropdown className='global-drop'>
                        <Dropdown.Toggle as="div" className='i-false global-drop-toggle'>                            
                            {globalSelect}{" "}
                            <i className="fa-solid fa-chevron-down" /> 
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='global-drop-menu'>
                        <Dropdown.Item >Dnes</Dropdown.Item>
                            {/* <Dropdown.Item onClick={()=>setGlobalSelect('Today')}>Today</Dropdown.Item>
                            <Dropdown.Item onClick={()=>setGlobalSelect('Week')}>Week</Dropdown.Item>
                            <Dropdown.Item onClick={()=>setGlobalSelect('Month')}>Month</Dropdown.Item> */}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className="card-body">
                    
                    <ProjectStatusBlogDonutChart
                    strediskaGraf={strediskaGraf}
                    strediskoCounts={strediskoCounts}
                    employesAll={employesAll}
                    barGraf={barGraf}
                    ostatniWorkers={ostatniWorkers}
                    />
                    <div className="project-date">
                        {barGraf.map((data, ind)=>(

                            <div className="project-media" key={ind}>
                                <p className="mb-0">
                                    <svg className="me-2" width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect y="0.5" width="12" height="12" rx="3" fill={data.color}/>
                                    </svg>{" "}
                                    {data.title}
                                </p>
                                <span>{data.pracovniky} Pracovníky</span>
                            </div>	
                        ))}  
                        <div className="project-media">
                                <p className="mb-0">
                                    <svg className="me-2" width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect y="0.5" width="12" height="12" rx="3" fill="#adadad"/>
                                    </svg>{" "}
                                    Ostatní střediska
                                </p>
                                <span>{ostatniWorkers} Pracovníky</span>
                            </div>                      
                    </div>
                    <div className="card-body cartBodyFlexRight paddingRemas">
                    <Link to={"/tovarny"} className='zobrazitvse' >zobrazit vše</Link>
                  </div>
                </div>
            </div>  
        </>
    );
};

export default ProjectStatusBlog;